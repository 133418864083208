.licence-box {
  width: 100%;
  max-width: 400px;
  margin: 1em auto;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 2px 2px 7px #bbb;
  border-radius: 5px;
  border: 1px solid #aaa;

  >p {
    margin-bottom: 0 !important;
  }

  .licence-expiration {
    margin-top: 1em;
    font-size: 0.8em;
    color: #444;
  }

  .licence-type {
    border: 1px solid #aaa;
    border-radius: 3px;
    margin-left: 0.5em;
    padding: 0.5em 1em;
    color: white;
    font-weight: 500;
  }

  .licence-type.standard {
    background-color: #fbb22b;
  }

  .licence-type.pro {
    background-color: #b92bfb;
  }
}
.splash-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo-spinner-preloader {
    width: 100%;
    height: 100%;
  }

  .powered-by {
    position: absolute;
    bottom: 2em;
    width: 115px;

    img {
      width: 100%;
    }
  }
}
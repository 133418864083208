﻿.breaking-time {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 3em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
        text-align: center;

        a {
            font-size: 1.2em;
            color: white;
            font-weight: bold;
        }
    }

    .socials-list {
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: auto;
        margin-right: auto;
    }

    .content {
        height: 40%;
    }

    .texts {
        height: 30%;
    }
    
    .contacts {
        text-align: center;
        font-size: 0.8em;

        a {
            color: white;
        }
    }
}

.breaking-time .contacts a:hover {
    color: $primary-color;
    text-decoration: none;
}

@keyframes animate-breaking-time-fullscreen-message {
    0% {
        opacity: 0;
        transform: translate(-50%,-50%) scale(0.0);
    }

    20% {
        opacity: 1;
        transform: translate(-50%,-50%) scale(1.0);
    }

    70% {
        opacity: 1;
        transform: translate(-50%,-50%) scale(1.1);
    }

    80% {
        opacity: 0;
        transform: translate(-50%,-50%) scale(2.5);
    }

    100% {
        opacity: 0;
        transform: translate(-50%,-50%) scale(2.5);
    }
}

.breaking-time .fullscreen-message {
    animation: animate-breaking-time-fullscreen-message 5s;
    animation-iteration-count: infinite;

    img {
        height: 1.5em !important;
    }
}

.breaking-time-ad {
    margin-top: 0.0em;
}

@media only screen and (max-width: $res0) {
    .breaking-time .contacts {
        font-size: 1.2em;
    }
}

// Mobile Landscape
// @media only screen and (max-width: $res0) and (orientation: landscape) {
// }

.ads-show {
    width: 100%;
    height: 100%;
}

.ads-show .item {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.ads-show img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.ads-show .item {
    display: none;
}

.ads-show .item.selected {
    display: block;
}

.ads-show .item div.centered {
    // Horizontally centered item's content
    text-align: center;
    position: relative;
}
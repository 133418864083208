﻿@keyframes animate-event-info-text {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(2.5);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.0);
  }
}

@keyframes zoomFade {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  25% {
    opacity: 1;
    transform: scale(1.3);
  }

  100% {
    opacity: 1;
    transform: scale(1.1);
  }
}

@keyframes vibrate {
  0% {
    transform: scale(1.1) translate(0);
  }

  25% {
    transform: scale(1.1) translate(-2px, 2px);
  }

  50% {
    transform: scale(1.1) translate(2px, -2px);
  }

  75% {
    transform: scale(1.1) translate(-2px, -2px);
  }

  100% {
    transform: scale(1.1) translate(2px, 2px);
  }
}

.game-started {
  width: 100%;
  text-align: center;
  position: relative;

  img {
    width: 6em;
  }

  .fullscreen-animated-event-info {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 100vh;
      width: auto;
      transform: scale(1.1);
      animation:
        zoomFade 1s forwards,
        vibrate 0.3s linear 4 1s;
    }

    h1 {
      position: absolute;
      top: 20vh;
      left: 50%;
      font-size: 2em !important;
      animation: animate-event-info-text 0.6s;
      animation-iteration-count: 1;
      transform: translate(-50%, -50%) scale(1.0);
      text-shadow: 2px 2px 7px #222;
    }
  }
}

.game-started .mobile,
.big-screen {
  .game-started p {
    font-size: 0.8em !important;
    margin-bottom: 0.5em;
  }

  .game-started h1 {
    font-size: 1.5em;
  }
}

.big-screen {
  .live-image {
    width: 10em;
    margin-top: 1.2em;
  }
}

.live-image {
  transform: rotate(0deg);
  animation-name: scale-in-out;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

@keyframes scale-in-out {
  from {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }

  to {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
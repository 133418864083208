$live-host-color: #C11F3A; // Cardinal Red
$single-session-color: #0F7173;
$background-color: #018E42;

.event-mask {
  h3 {
    margin-top: 1em;
  }

  .MuiTextField-root {
    width: 100%;
  }

  .centered-item {
    display: flex;
    align-items: center;

    >* {
      width: 100%;
    }
  }

  .now-button {
    padding-left: 1em;
    padding-right: 1em;
  }

  .location-types {
    flex-direction: row;
  }

  .date-time-picker {
    display: block;
  }

  table.game-automation {
    font-size: 0.7em;

    td {
      >b {
        display: block;
      }
    }

    td:last-child {
      width: 6em;
    }
  }

  .game-speed {
    margin-top: 1em;
    margin-left: 0.5em;

    .speed-radio-group {
      flex-direction: row;
    }
  }

  table.notifications {
    margin-top: 1em;

    td {
      padding: 0.3em 0;
    }
  }

  .free-tickets {
    margin-top: -0.5em;
    margin-bottom: 1.5em;
    width: 100%;

    >div {
      margin-right: 0.8em;
    }

    span.fas {
      margin-left: 0.6em;
      width: 1.2em;
      color: #e69316;
    }
  }
}

.home.page {
  .game-mode {
    .game-modes {
      .card {
        margin-bottom: 2em;

        .description {
          font-weight: 300;
          color: white;
          max-width: 100%;
          margin: 1em;
        }
      }
    }
  }

  .game-mode {
    .game-modes {
      margin-top: 1em;

      .MuiRadioGroup-root {
        flex-direction: row;
        justify-content: space-around;
      }

      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 25em;
        padding: 3em 0;
        border-radius: 3px;
        color: white;
        font-weight: 600;
        box-shadow: 5px 5px 10px #bbb;

        svg.icon {
          font-size: 2.5em;
          margin-bottom: 0.25em;
          color: white;
        }

        &.live-host {
          background-color: $live-host-color;
        }

        &.single-session {
          background-color: $single-session-color;
        }

        &.continuous-play {
          background-color: $background-color;
        }

        label {
          span {
            font-size: 0.8em;
          }
        }
      }

      .MuiRadio-root {
        color: #ccc;

        &.Mui-checked {
          color: white;
        }
      }
    }

    .description {
      margin: auto;
      margin-top: 1.5em;
      text-align: center;
      color: #222;
      font-size: 0.9em;
      max-width: 60%;
    }
  }
}

@media only screen and (max-width: $tablet-width) {
  .game-mode {
    fieldset {
      .MuiRadioGroup-root {
        >div {
          padding: 1em 0;

          svg.icon {
            margin-top: 0.5em;
            font-size: 2.0em;
          }

          label {
            span {
              font-size: 0.6em;
            }
          }
        }
      }
    }

    .description {
      max-width: 90%;
    }
  }
}
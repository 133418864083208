// #__react-alert__ {
//     position: absolute;
//     z-index: 1100;
// }

.outlined {
    border: 1pt solid #444;
    border-radius: 5px;
    margin: auto;
    padding: 0.2em 0.5em;
}

.iqspace-slide {
    display: block;
    position: relative;

    .logo {
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%);
        width: 12em;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .tagline {
        position: absolute;
        top: calc(50% - 35px);
        left: 50%;
        transform: translate(-50%);
        width: 100%;
        margin: 0;
        font-size: 1.3em;
        font-weight: 200;
        color: white;
        text-align: center;
    }

    .iqspace-icons {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%);
        width: 100%;
        max-width: $target-width;

        img {
            margin: auto;
            max-width: 7em;
        }
    }

    .start-now-button {
        position: absolute;
        bottom: 15%;
        left: 50%;
        transform: translate(-50%);

        border-color: white;
        color: white;
        margin-left: auto;
        margin-right: auto;
        font-family: "Poppins";
        letter-spacing: 2px;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@media only screen and (max-width: $res0) {
    .iqspace-slide {
        .tagline {
            width: 80%;
        }
    }
}

.iqspace-bg-animation {
    background: linear-gradient(-45deg, $xanthous-color, $primary-color, $caribbean-color, $secondary-color);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
}

.slide.iqspace-slide-0 {
    @extend .iqspace-bg-animation;

    .tagline {
        font-size: 2em;
    }
}

.slide.iqspace-bg-slide {
    @extend .iqspace-slide;

    background-size: cover;
    background-position: center;

    .fullscreen-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.slide.iqspace-slide-1 {
    background-image: url("../images/iq-space-quiz.jpg");
}

.slide.iqspace-slide-2 {
    background-image: url("../images/iqspace-li.jpg");
}

.work-with-us {
    padding-bottom: 1.5em;
    text-align: center;

    h1 {
        margin-bottom: 1em;
    }

    h3 {
        margin-bottom: 1.5em;
    }

    img {
        max-width: 80%;
    }

    .logo-spinner-preloader {
        margin: auto;
        width: 140px;
    }

    .entertainer-application {
        .field {
            min-width: 300px;
        }

        .allow-discoverable {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 1em;
            margin-bottom: 1em;
        }

        .country-field,
        .timezone-field,
        .game-language-field,
        .locale-field {
            min-width: 70px;
        }
    }

    .localization {
        h2 {
            margin-top: 1em;
        }

        .timezone-field {
            margin-top: 1em;
        }
    }

    .with-overlay {
        color: white;
        background: white;
    }
}

@media only screen and (min-width: $res1) {
    .work-with-us {
        img {
            max-width: 50%;
        }

        .entertainer-application {
            .field {
                width: 100%;
            }
        }

        button[type=submit] {
            margin-top: 1em;
        }
    }
}

@media only screen and (max-width: $res0) {
    .work-with-us {
        .entertainer-application {
            .field {
                width: 100%;
            }
        }
    }
}

.store {

    h1,
    h2,
    h3,
    h4 {
        text-align: center;
    }

    h2 {
        font-size: 0.9em;
    }

    h4 {
        font-size: 1.4em;
        display: block;
        clear: left;
    }

    .socials-list {
        width: 100%;
        margin-bottom: 1em;
    }

    .store-logo {
        height: 128px;
        width: auto;
        margin: 15px;
    }
}

.page.following {
    .store-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin-bottom: 1em;

        .avatar {
            width: 100px;
            height: 100px;
        }

        h2 {
            text-transform: uppercase;
            color: #555;
            font-weight: 700;
            text-align: left;
            letter-spacing: .15em !important;
            font-family: Poppins, Helvetica, "sans-serif";
            font-size: 0.8em;
            text-align: center;
            margin-top: 0.8em;
        }
    }
}

.team {
    width: 100%;

    h3 {
        font-size: 1em;
        text-align: center;
    }

    .members {
        display: flex;
        justify-content: center;
        flex-direction: row;
        height: 80px;

        .member {
            float: left;
            margin-right: 5px;

            .avatar {
                border: 2px solid white;
                width: 64px;
                height: 64px;
                cursor: pointer;
                margin: 0 !important;
            }
        }
    }
}

.business-card {
    width: 500px;
    overflow: auto;

    .avatar {
        margin: 10px;
        width: 128px;
        height: 128px;
    }

    h1 {
        margin-top: 20px;
        font-size: 16pt;
        font-weight: lighter;
        text-transform: none;
        color: #282828;
        cursor: default;
    }

    h2 {
        text-transform: uppercase;
        text-align: left;
        font-size: 9pt;
        font-weight: bold;
        letter-spacing: 1px;
        cursor: default;
    }

    .contact {
        margin: 5px 0;
        font-size: 12pt;
        display: flex;

        .icon,
        a {
            color: #444;
        }

        .icon {
            width: auto;
            margin-right: 10px;
        }
    }
}

@media only screen and (max-width: $res0) {
    .team {
        .members {
            .member {
                .avatar {
                    width: 48px;
                    height: 48px;
                }
            }
        }
    }

    .business-card {
        width: auto;

        .avatar {
            width: 64px;
            height: 64px;
        }
    }
}

.events {
    h2 {
        margin-bottom: 0.5em;
        font-size: 1.2em;
        text-align: center;
        color: #444;
    }

    .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        >p {
            margin: 2em 0 3em 0;
        }

        .card {
            width: 300px;
            margin-left: 5px;
            margin-right: 5px;
            margin-bottom: 15px;
            padding: 0;

            .content {
                padding: 0.5em;
            }

            h2 {
                text-transform: uppercase;
                font-weight: 600;
                font-size: 0.8em;
                color: $raisin-black-color;
                height: 2.5em;
                margin-bottom: 0;
                cursor: pointer;
            }

            h3 {
                font-size: 0.7em;
                font-weight: 500;
                color: #4e4e4e;
                height: 1.7em;
                margin: 0;
                cursor: pointer;
                text-align: center;
            }

            h3.sold-out {
                margin-top: 5px !important;
                margin-left: 8px !important;
            }

            .image-box {
                display: flex;
                height: 180px;

                img {
                    height: 180px;
                }

                img[src="/assets/iqspace/images/placeholder.png"] {
                    width: 128px;
                    height: 128px;
                    margin: auto;
                    opacity: 0.5;
                }
            }

            .live {
                position: absolute;
                top: 0.5em;
                right: 0.5em;
            }
        }
    }

    .event-extras-tabs {
        width: 100%;
        margin-top: 1em;
        margin-left: 1em;

        .played-at {
            min-width: 10em;
        }
    }
}

.ticket-card {
    .image-box {
        margin: 1em 0;
    }

    .voucher-application-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1em;
        margin-bottom: 0em;

        button {
            margin-bottom: 0em;
            margin-top: 1em;
        }

        .linear-progress {
            width: 100%;
            margin-top: 1em;
        }
    }
}

.events.past {
    .card {
        width: 200px;
    }
}

@media only screen and (max-width: $res0) {
    .events {

        .list>a,
        .list .card {
            width: 100%;
        }
    }

    .events.past {

        .list>a,
        .list .card {
            width: 46%;
        }
    }
}

.call-to-action {
    font-family: Rubik !important;
    font-weight: 500;
    color: white !important;
}

.play-the-demo-button {
    button {
        margin: 0;
        margin-top: 1em;
    }
}

@media only screen and (max-width: $res0) {
    .play-the-demo-button {
        button {
            margin: auto;
            margin-top: 1em;
        }
    }
}

.play.page {
    padding-bottom: 1em;

    >div {
        justify-content: center;
    }

    b {
        font-weight: 600;
    }

    .role {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        h2 {
            margin-top: 1em;
            margin-bottom: 1em;
            color: $primary-color;
        }

        h2,
        p {
            text-align: center;
        }

        p {
            margin-bottom: 0;
        }

        img {
            max-width: 256px;
            margin: auto;
            margin-top: 2em;
            margin-bottom: 2em;
        }

        button {
            margin: auto;
            margin-top: 1em;
        }

        button.signup {
            background-color: #d127a8;
            color: white;
        }

        .iq-button-link {
            button {
                height: 4em;
            }
        }

        .outlined {
            height: 3.5em;
            padding: 0 1em;
            display: flex;
            align-items: center;
        }
    }
}

@media only screen and (max-width: $res0) {
    .play.page {
        .role {
            img {
                max-width: 50%;
            }
        }
    }
}

.live {
    background-color: $chilli-red-color;
    color: white;
    border: 1px solid $primary-color;
    font-weight: bold;
    font-family: Rubik;
    font-size: 1em;
    padding: 2px 5px;
    border-radius: 4px;
    animation: flickerAnimation 0.8s infinite;
    cursor: default;
}

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }
}

.breadcrumbs {
    height: 3em;
    margin-left: 5px;

    .MuiBreadcrumbs-root {
        color: #555 !important;

        .MuiAvatar-root {
            margin-right: 0.5em;
        }

        a {
            color: #555 !important;
            display: flex;
            align-items: center;

            img {
                height: auto;
            }
        }

        a:hover {
            color: $primary-color;
        }
    }
}

@media only screen and (max-width: $res0) {
    .breadcrumbs {
        margin-left: 7px;
    }
}

.shareable-event-links {
    @extend .outlined-with-label;

    justify-content: center;
    align-items: center;
    margin-bottom: 0.5em;

    .share-icon {
        color: #ddd;
        margin-left: 0.7em;
        margin-top: -0.2em;
    }

    .modes {
        background-color: transparent;
        margin-right: 10px;

        button {
            min-width: 20px;
            padding: 0.3em;
        }
    }

    a {
        margin-top: -0.3em;
    }

    .details-label {
        text-transform: uppercase;
        font-weight: 500;
        margin-top: -0.4em;
        margin-left: 1em;
        cursor: default;
        font-size: 0.8em;
        color: #757575;
    }
}

@media only screen and (max-width: $tablet-width) {
    .shareable-event-links {
        margin-bottom: 0.25em;
        height: 50px;
        padding-top: 9px;
        padding-left: 0;
        padding-right: 5px;
    }
}

@media only screen and (max-width: $res0) {
    .shareable-event-links {
        max-width: 100%;

        .details-label {
            display: none;
        }
    }
}

.page.module {
    position: relative;
    max-width: $target-width;
    margin: auto;

    .quick-module {
        padding: 0.0em 1em 2.5em 1em;
    }

    .view {
        padding: 1em 0 4em 0;
    }

    h3 {
        font-size: 1.0em;
        font-weight: 600;
        text-transform: uppercase;
        color: $primary-color;
    }

    .insert-button {
        position: absolute;
        right: 1em;
    }

    $small-image-width: 196px;
    $small-image-height: 196px;
    $small-image-padding: 10px;

    .image-col.small {
        width: 234px;
        margin-left: 0;

        .media-image {
            width: $small-image-width + $small-image-padding * 2;
            min-height: $small-image-width + $small-image-padding * 2;

            .image {
                width: $small-image-width;
                height: $small-image-height;
            }
        }
    }

    .module-footer {
        width: 100%;
        max-width: $target-width;
        margin-top: 1em;
        display: flex;
        border-top: 1px solid #bbb;
        background-color: white;
        position: fixed;
        bottom: 0;

        .right {
            position: absolute;
            right: 1em;
            display: flex;
        }
    }
}

@media only screen and (max-width: $res0) {
    .page.module {
        padding-top: 88px;

        .media-image {
            margin: 2em auto;
        }

        .module-footer {
            max-width: 100%;
        }
    }
}

.module {
    padding-left: 13px;
    padding-right: 13px;
}

// Events

.page.events {
    .MuiSkeleton-root {
        background-color: rgba(100, 100, 100, 0.2);
        margin: auto;
    }

    .placeholder-thumb {
        opacity: 0.4;
    }
}

.mask {
    .MuiTextField-root {
        width: 100%;
    }
}

// Followers

.error-message {
    color: #bb0000;
    font-size: 0.9em;
    margin-top: 0.3em;
    width: 100%;
}

.add-to-new-playlist {
    display: block;
    text-align: center;
}

.mode-tooltip {
    font-size: 1.5em;
    padding-top: 1em;
}

.no-items {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 0.9em;
    color: $onyx-color;

    i {
        font-size: 60pt;
        margin-bottom: 10px;
        color: #bdbdbd;
    }
}

.questions-view {
    .top-menu {
        display: flex;
        justify-content: center;
        padding-bottom: 1em;
    }

    .playlist-questions {
        .empty-playlist-message {
            padding: 1em 0;
        }

        .top-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 1em;

            gap: 0.5em;
        }

        .injest-questions-form {
            display: flex;
            align-items: center;

            .action-button {
                margin-left: 0.5em;
            }
        }

        .selection-actions {
            display: flex;
            position: fixed;
            bottom: 70px;

            .addrange-to-playlist,
            .removerange-from-playlist {
                color: white !important;
            }

            .removerange-from-playlist {
                margin-left: 0.5em;
            }
        }
    }
}

.playlist-questions table,
table.dragged-table {
    th {
        .category-col {
            background-color: transparent;
        }
    }

    tr.playing {
        background-color: $dark-bg-color;
        border-radius: 3px;

        td {
            color: white;
        }

        .play-col {
            justify-content: center;

            button.play-question {
                display: none;
            }

            .live-question {
                display: block;
                animation: flickerAnimation 0.8s infinite;
            }
        }

        .category-col {
            background-color: transparent;

            >div {
                border-color: #777;
            }
        }
    }

    tr {
        display: flex;

        .select-col {
            min-width: 55px;
            max-width: 55px;
        }

        .play-col {
            min-width: 45px;
            max-width: 45px;

            .live-question {
                display: none;
            }
        }

        .type-col {
            min-width: 3em;
            max-width: 3em;

            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 100%;
                border-radius: 3px;
            }
        }

        .text-col {
            flex-grow: 1;
        }

        .category-col {
            min-width: 250px;
            max-width: 300px;
            // background-color: #f5f5f5;
            display: flex;
            justify-content: center;
            align-items: center;

            >div {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 180px;
                border: 1px solid #ddd;
                border-radius: 0.5em;
                padding: 0.5em;
                color: white;
                font-size: 0.9em;

                >img {
                    height: 1em;
                    margin-right: 0.35em;
                }
            }
        }

        .exact-col {
            min-width: 50px;
            max-width: 50px;
        }

        .menu-col {
            min-width: 50px;
            max-width: 50px;

            .questions-menu-button {
                margin-top: 6px;
            }
        }

        td,
        .td-v-center {
            min-height: 3.0em;
        }
    }

    th.category-col {
        background-color: transparent;
    }
}

@media only screen and (max-width: $mobile-width) {

    .playlist-questions table,
    table.dragged-table {
        max-width: 85%;
        margin: auto;
        overflow-x: hidden;
        font-size: 0.9em;

        .category-col {
            display: none !important;
        }

        tr {

            td,
            .td-v-center {
                height: auto;
            }
        }
    }
}

.quick-new-playlist {
    display: flex;
    flex-direction: row;
    align-items: center;

    .MuiTextField-root {
        flex-grow: 1;
    }
}

.tickets.page {
    padding-bottom: 1em;

    h1 {
        text-align: center;
    }

    .datagrid-box {
        width: '100%';

        .MuiDataGrid-columnHeaderTitle {
            font-weight: 600;
        }
    }
}

.following.page {
    padding-bottom: 1em;

    h1 {
        text-align: center;
    }
}

.store-avatar {
    cursor: pointer;

    .MuiChip-label {
        color: white;
    }
}

.MuiAlert-message {
    .store-avatar {
        margin-left: 1em;
    }
}

.colors {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1em 0;

    .palette {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: calc(2.6em * 12);

        .color {
            width: 2em;
            height: 2em;
            margin: 0.3em;
            border: 0.3em solid white;
            border-radius: 50%;
        }

        .selected.color {
            border-color: $primary-color;
        }
    }
}

.quizmaster.page {
    .animated-bg-title {
        position: relative;
        margin: 0 1em;

        .logo {
            img {
                width: 50%;
                margin-top: 3em;
            }
        }

        h1 {
            text-transform: uppercase;
            font-family: 'Rubik';
            font-weight: 400;
            font-size: 2.2em;
            line-height: 1.2em;
            margin: 0.5em;
            margin-top: 0;
            padding-top: 0;
        }
    }

    .content {
        padding-right: 1em;

        h1 {
            line-height: 2.2em;
        }

        h2 {
            font-size: 1.4em;
            padding-bottom: 0.5em;
        }

        h3 {
            font-size: 1.2em;
            padding-bottom: 0.5em;
            font-weight: 500;
            color: $primary-color;
        }

        .browser {
            margin-top: 3em;
            margin-bottom: 3em;
            margin: 2em auto;
        }

        .slick-slider {
            img {
                width: 100%;
            }
        }
    }

    img.full-width {
        width: 100%;
        max-width: 30em;
        margin: auto;
        display: block;
    }

    .iq-button-link {
        margin-top: 2em;
        margin-bottom: 1.5em;
    }

    .contact-form {
        flex-basis: 70%;
        max-width: 70%;
        margin-bottom: 1.0em;
    }
}

@media only screen and (max-width: $mobile-width) {
    .quizmaster.page {
        .animated-bg-title {
            h1 {
                font-size: 1.5em;
                margin-bottom: 1em;
            }
        }

        .content {
            padding-left: 1em;

            h1 {
                line-height: 1em;
                font-size: 1.3em;
            }

            .slick-slider {
                width: 300px;
                margin: 2em auto;
            }
        }

        .contact-form {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
}
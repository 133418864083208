.payment-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  #card-element {
    width: 100%;
  }

  .accept-conditions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em 0;

    p {
      margin-bottom: 0;
    }
  }

  button {
    min-width: 120px;
    max-width: 100%;
    height: 3em;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255, 0.7);
  }
}

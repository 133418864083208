@import "./action-button";
@import "./modal";
@import "./overlay";
@import "./action-button";
@import "./form-image";
@import "./media-image";

.MuiButton-contained {
    color: white !important;
}

.display-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.quiz-widget {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    p {
        text-align: center;
    }

    h1 {
        margin-top: 0.3em;
    }

    .event {
        text-align: center;

        .uppercase-bold {
            text-align: center;
            font-size: 0.8em;
            color: #444;
        }
    }

    .no-events {
        text-align: center;
        cursor: default;

        i {
            padding: 2rem 0;
            font-size: 48px;
            color: #d8d8d8;
        }
    }

    .start-when {
        margin-top: 1em;
    }
}

.quiz-widget {
    p.game-expired {
        margin-top: 1em;
    }

    .console {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 3px;
        color: #222;
        margin: 0.5em 0.5em;

        .cancel-game {
            text-transform: uppercase;
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 0.7em;
            width: 33%;
            display: flex;
            align-items: center;

            .cancel-by {
                margin-left: calc(48px + 1.5em);
                margin-right: 0.5em;
                color: $danger-color;
            }

            .countdown-box .countdown .time-quantity-box {
                padding: 0 0.1em;

                .label {
                    font-weight: 500;
                }
            }
        }

        .main-controls {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 33%;

            .play,
            .stop {
                margin: 5px;
            }
        }

        .ranking-controls {
            width: 33%;
            display: flex;
            align-items: center;
            justify-content: center;

            .invite-players {
                margin-top: 0;
            }

            .teams {
                margin-right: 5px;
            }

            .final.leaderboard {
                margin-left: 5px;
            }
        }
    }

    .tabs-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin-right: 0.5em;

        .tab-content {
            flex-grow: 1;
            overflow: auto;

            border-left: 1px solid #dee2e6;
            border-right: 1px solid #dee2e6;
            border-bottom: 1px solid #dee2e6;
            background-color: #fafafa;
            padding-right: 5px;
            padding-left: 5px;
        }

        .nav-item.active {
            background-color: #fafafa;
            border-bottom-color: transparent;
        }

        .tab-pane {
            overflow: auto;
        }

        .players {
            .head {
                margin-top: 1em;
                font-size: 0.8em;
                color: #444;
                font-family: "Poppins";
                padding-bottom: 0.5em;

                .live {
                    margin-right: 0.5em;
                }
            }
        }
    }
}

.question-controls {
    padding: 0em 1.5em;
}

.quiz-widget {
    .invite-players-controls {
        white-space: nowrap;
        border: 1pt solid #d1d1d1;
        border-radius: 5px;
        height: 53px;
        padding: 5px;
        margin: 3px auto;

        position: absolute;
        z-index: 1;
        right: 15px;
    }

    .playlist-questions {
        .empty-playlist-message {
            padding: 1em 0.5em 0em 0.5em;
        }
        
        .table-responsive {
            overflow-x: hidden;
        }
    }
}

@media only screen and (min-width: $tablet-width) {
    .console {
        .tabs-container {
            margin-top: -30px;
        }
    }
}

.controls-separator {
    width: 1px;
    height: 40px;
    background-color: #ddd;
    margin: 0 5px;
}

@media only screen and (max-width: $res0) {
    .quiz-widget {
        .console {
            display: block;
            margin-bottom: 0;
            flex-direction: column;

            .cancel-game {
                width: 100%;
                justify-content: center;

                .cancel-by {
                    margin-left: 0;
                }
            }

            .main-controls {
                width: 100%;
            }

            .ranking-controls {
                width: 100%;
                margin-top: 0.3em;
                margin-bottom: 1em;
            }
        }
    }

    .controls-separator {
        height: 32px;
    }
}

.countdown>div {
    display: inline;
}

.no-playlists {
    padding-top: 2em;

    button {
        margin-bottom: 1em;
    }
}

@media only screen and (max-width: $res0) {
    .quiz-widget .cancel-game {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }

    .quiz-widget {
        padding-left: 0;
        padding-right: 0;
    }
}

.quiz-widget .action-button:hover {
    border: 1pt solid #444;
}

.quiz-widget .action-button:active {
    background-color: #f5f5f5;
}

table tr.playing .delete-player {
    display: none;
}

.countdown-to-start {
    margin-bottom: 1em;

    .countdown {
        font-size: 1.0em;

        .time-quantity-box {
            >* {
                height: 23px;
            }

            .title {
                font-weight: bold;
            }
        }
    }

    p {
        font-size: 0.8em;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }
}

.notifications-tab {
    h3 {
        margin-top: 2em;
        font-size: 1.2em;
    }

    p,
    h3 {
        color: #282828;
        text-align: left;
    }

    .row {
        margin: 0;
    }

    .notifications-form {
        width: 100%;

        .field {
            width: 100%;
        }

        button[type=submit] {
            margin: 0.5em 0;
        }
    }
}

.disabled-form {
    opacity: 0.5;

    * {
        pointer-events: none;
    }
}

.category-dot {
    float: left;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-top: 4px;
    margin-right: 6px;
}
@import url('https://fonts.googleapis.com/css?family=Hind:400,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Karla:ital,wght@0,200..800;1,200..800&display=swap');

$res0: 600px; // Mobile
$res1: 1001px;
$res2: 1301px;

// IQ Space main palette

$primary-color: #9A18C6; // Dark Violet // #d1278a;
$secondary-color: #EE4B6A; // Bright Pink (Crayola) // #3F84E5; //#2d8dff;

$xanthous-color: #FFBC42; // Typical for titles
$amaranth-color: #DF3B57; // Aamaranth
$caribbean-color: #0F7173; // Caribbean Current

$primary-font: 'Karla', sans-serif;
$secondary-font: 'Rubik', sans-serif;

$navbar-height: 70px;
$navbar-z-index: 1030;
$sidenav-z-index: 1033;
$menu-categories-z-index: 99;

@import "./fullscreen-message";
@import "./countdown";
@import "quiz-box";
@import "timer";
@import "breaking-time";
@import "game-started";
@import "start-view";
@import "recorder";

* {
	font-family: $primary-font;
}

html,
body {
	font-size: 10px;

}

.connection-info-overlay {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	.logo-spinner-preloader {
		height: 2em;

		img {
			height: 2em;
		}
	}

	p {
		font-size: 0.6em;
		text-transform: uppercase;
		color: white;
		font-family: 'Poppins';
		text-align: center;
	}
}

.embedded-quiz-container {
	width: 100%;

	* {
		cursor: default !important;
	}

	.ranking-container {
		overflow-y: hidden;
	}
}


.branded-button {
	margin: auto;
	display: flex;
	background-color: white !important;
	color: black !important;
	padding: 1em !important;

	img {
		height: 2em;
		margin: 0 0.5em 0 0;
		display: block;
	}
}

.game-view {
	font-family: $primary-font;
	background-attachment: fixed;
	// background: radial-gradient(ellipse at center, $bgColor0 0%, $bgColor1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	color: white;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	user-select: none;
	overflow: hidden;

	background: linear-gradient(-45deg, $xanthous-color, $primary-color, $caribbean-color, $secondary-color);
	animation: gradient 15s ease infinite;
	background-size: 400% 400%;
}

.game-view h1 {
	font-size: 1.0em;
	font-weight: 600;
	font-family: $secondary-font;
	text-transform: uppercase;
	color: white;
	margin: auto;
	margin-bottom: 1em;
	margin-top: 0;
}

.game-view h2 {
	font-size: 0.8em;
	// font-size: 18pt;
	font-weight: bold;
	font-family: $primary-font;
	text-align: center;
	text-transform: uppercase;
	padding-top: 5px;
	margin-top: 0;
}

.game-view h3 {
	font-size: 0.7em;
	font-family: $primary-font;
}

.game-view h4,
.game-view h5,
.game-view p,
.game-view span {
	font-size: 0.6em;
}

.v-centered {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.hv-centered {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.container-fluid {
	max-width: 1600px;
}

.game.container-fluid {
	max-width: 100%;
	padding: 0;
}

.navbar-brand {
	margin-left: auto;
	margin-right: auto;
}

img.main-logo {
	height: 42px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

div.clear {
	clear: both;
}

.fullscreen-view {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.fullscreen-view.overlay {
	background-color: rgba(0, 0, 0, 0.2);
	color: white;
}

.fullscreen-view.overlay .centered {
	text-align: center;
	left: 50%;
	transform: translate(-50%, -50%);

	p {
		margin-top: -60px;
	}
}


.centered {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.centered-comic-message {
	background: white;
	position: absolute;
	width: 98%;
	max-width: 512px;
	padding: 20px;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	text-align: center;
	border-radius: 5px;

	h1 {
		color: $primary-color;
	}
}

.centered-comic-message * {
	color: black;
}

.game-completed {
	overflow: auto;
	margin: auto;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: transparent;
	font-size: 1.2em;
	text-align: center;
	// padding-bottom: 100px;
	// padding-top: 6em;

	.mini-leaderboard {
		width: 100%;
		max-width: 512px;
	}

	.socials i {
		color: white;
		font-size: 1.5em;
	}

	button.show-leaderboard {
		color: white;
		margin-top: 1em;
	}

	.like-this-game {
		margin-top: 1.4em;
		max-width: 10em;

		.socials-list {
			margin-top: 1em;
			justify-content: center;

			a {
				font-size: 1em;
			}
		}
	}

	h2 {
		color: #fed64b;
	}

	h3 {
		color: #fed64b;
		font-size: 0.5em;
		margin-top: 2em;
		margin-bottom: 0;
	}

	p {
		color: white;
		margin: 0;
	}

	h1 {
		color: #fff;
	}

	.trophy-img {
		width: 6em;
		margin-bottom: 0.35em;
	}

	.share-leaderboard {
		button {
			margin: 2px;

			svg {
				width: 32px;
			}
		}

		.download-leaderboard {
			color: white;
		}
	}
}

.hide-leaderboard {
	color: white !important;
	position: absolute !important;
	right: 0.5em;
	top: 20px;
	z-index: 1100;
}

.game-completed .new-game-starting-soon {
	font-size: 0.5em;
	color: #ddd;
}

.breaking-news {
	position: absolute;
	width: 100%;
	bottom: 0;
	padding: 5px;
	height: 54px;
	overflow: hidden;
	word-break: keep-all;
	white-space: nowrap;
}

.breaking-news h3 {
	position: absolute;
	width: 100%;
	height: 100%;
	font-weight: 600;
	font-family: $secondary-font;
	text-transform: uppercase;
	margin: 0;
	line-height: 50px;
	text-align: center;
	transform: translateX(100%); // Starting position
	animation: marquee 15s linear infinite;
}

@keyframes marquee {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(-100%);
	}
}

@media only screen and (max-width: $res0) {
	.breaking-news h3 {
		animation: marquee-mobile 10s linear infinite;
		transform: translateX(100%); // Starting position
		text-align: left;
	}
}

@keyframes marquee-mobile {
	0% {
		transform: translateX(120%);
	}

	100% {
		transform: translateX(-150%);
	}
}

.quit-game-button {
	position: absolute;
	font-size: 1.2em;
	top: 0.55em;
	left: 4.8em;
	border-radius: 50%;
	background-color: white;
	color: $primary-color;
	text-decoration: none;
	padding: 0.12em 0.3em 0.3em 0.3em;
	background: rgba(0, 0, 0, 0.4);
	width: 1.8em;
	height: 1.8em;
	text-align: center;
	color: white;
	z-index: 1;
}

.show-players-button {
	position: absolute;
	font-size: 1.2em;
	top: 0.55em;
	right: 25%;
	background: rgba(0, 0, 0, 0.4);
	padding: 0.12em 0.3em 0.3em 0.3em;
	margin: 0;
	width: 1.8em;
	height: 1.8em;
	text-align: center;
	border-radius: 50%;
	color: white;
	z-index: 1;
}

.rounded-button {
	width: 200px;
	padding: 6px;
	border-radius: 25px;
	cursor: pointer;
}

.rounded-button i {
	color: white;
	margin-right: 10px;
}

.view-mode-icon {
	position: absolute;
	height: 13em;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) rotate(0deg);
	animation-name: view-mode-icon-spin;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-direction: alternate;
}

@keyframes view-mode-icon-spin {
	from {
		transform: translate(-50%, -50%) rotate(0deg);
	}

	to {
		transform: translate(-50%, -50%) rotate(30deg);
	}
}

.view-mode-icon img {
	height: 13em;
}

.player-view button.toggle-chat-view-mode-button {
	height: 2em;
	width: 100%;
	background-color: #f5f5f5;
}

.notification-line {
	position: relative;
}

.notification-line .message {
	display: block;
	line-height: 1.4em;
	margin-top: 0em;
	margin-left: 2.4em;
	margin-right: 0.15em;
}

tooltip {
	font-size: 1.5em;
	color: white;
}

.player-view {
	touch-action: none;
	pointer-events: none;
	position: absolute;
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	bottom: 0.3em;
	overflow: hidden;
	min-height: 2em;
	z-index: 1;

	.options-bar {
		touch-action: auto;
		pointer-events: auto;

		button {
			color: white;
			font-size: 1em;

			.fas {
				font-size: 0.9em;
				width: 1.3em;
			}
		}
	}
}

.access-game-overlay {
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.3);
	color: #ddd;
	pointer-events: none;

	.access-game-content {
		display: flex;
		flex-direction: column;
		background: rgba(255, 255, 255, 0.9);
		align-items: center;
		border-radius: 4px;
		color: black;
		padding: 2em;
		min-width: 24em;
		backdrop-filter: blur(3px);

		svg {
			width: 2em;
			height: 2em;
			margin-bottom: 1em;

			path {
				color: $secondary-color
			}
		}

		button,
		a,
		input {
			pointer-events: auto;
		}

		b {
			color: $primary-color;
			font-weight: 600;
		}

		.link-button {
			font-size: 0.7em;
			margin-top: 1em;
		}

		.play-button {
			pointer-events: auto;
			font-weight: 400;
			font-family: $secondary-font;
			margin: 1em;
		}

		.gamename-form {
			width: 100%;
			margin-top: 1em 0;
			text-align: center;

			>p {
				margin-bottom: 2em;
			}

			h2 {
				display: none;
			}

			small,
			a {
				font-size: 0.7em;
			}

			.more-settings {
				margin-top: 2em;
				display: block;
			}
		}
	}
}

@media only screen and (max-width: $res0) {
	.access-game-overlay {
		.access-game-content {
			width: 95%;
			min-width: 95%;
			max-width: 95%;
		}
	}
}

.chat-box {
	touch-action: auto;
	pointer-events: auto;
	background-color: white;
	width: 100%;
	max-width: 25em;
	margin-right: 0.3em;

	.skeleton-preloader {
		margin: 1em;
	}

	.access-to-chat {
		margin: 0;
		font-size: 0.65em;
		color: $primary-color;
		text-align: center;
	}

	.no-chat-messages {
		text-align: center;
		margin-top: 0.8em;
		margin-bottom: 0;
		font-size: 0.8em;
	}

	.toggle-chat {
		text-align: center;
		cursor: pointer;
	}

	.name {
		font-family: "Poppins";
		font-weight: 400;
	}

	.you {
		color: $primary-color;
	}

	.line {
		padding: 0 0.5em;

		p {
			color: #444;
		}
	}

	#chat {
		max-height: 16em;
		overflow-y: auto;
	}

	form {
		margin-bottom: 0;
	}

	.options {
		display: flex;
		flex-direction: row;
		margin-bottom: 0.5em;
		align-items: center;

		input[type=text] {
			flex-grow: 1;
			margin-left: 0.5em;
			height: 2em;
			font-size: 0.8em;
		}

		button {
			display: block;
		}
	}
}

@media only screen and (max-width: $res0) {
	.player-view {
		bottom: 0;

		.chat-box {
			margin-right: 0;

			#chat {
				max-height: 10em;
			}
		}

		.options-bar {
			position: relative;
			display: flex;
			justify-content: center;
			width: 100%;
			left: 0;

			button {
				font-size: 1.1em;
			}
		}
	}
}

.player-view .chat-container {
	display: none;
}

.mobile {
	.player-view .chat-container {
		display: flex;
		align-items: stretch;
		flex-direction: column;
		width: 100%;
		left: 0;
		bottom: 0;
		height: 2em;
		padding-bottom: 2.29em;
		padding-top: 0.1em;
	}
}

.player-view .chat-container>form .send-text:focus {
	box-shadow: 0 0 1pt 1pt $primary-color;
}

.access-game-button {
	background-color: $primary-color;
	color: white;
	border-radius: 25px !important;
}

.react-responsive-modal-modal .modal-overlay button {
	margin: auto auto !important;
	display: block;
}

.video-player {
	margin-top: 3.5em;
}

.video-player button {
	margin: 1em auto;
	font-size: 1em;
	color: white;
	display: block;
}

// Quiz game view root

.quiz-game {
	position: absolute;
	width: 100%;
	height: 100%;
	min-height: 100%;

	>div {
		width: 100%;
		height: 100%;
	}

	.big-screen {
		margin-top: -85px;

		.chat-box {
			font-size: 1rem;

			li {

				.name,
				p {
					font-size: 0.8em;
				}
			}
		}

		.game-completed {
			max-width: 60%;

			.show-leaderboard {
				display: none;
			}
		}

		.video-player {
			margin-top: 70px;
		}

		.bottom-bar {
			display: none;
		}
	}
}

.embedded-quiz-container {
	.widget {
		width: 100%;
		height: 100%;

		.fullscreen-icon {
			display: none;
		}

		.game-completed {
			.show-leaderboard {
				display: none;
			}

			.trophy-img {
				width: 4em;
			}
		}

		.ranking-container,
		.ranking-col-groups-container {
			overflow: hidden;
		}
	}
}

.game-logo {
	position: absolute;
	left: 50%;
	transform: translateX(-50%) scale(1);
	z-index: 1099;
	top: 0.5em;

	img {
		height: 56px;
	}
}
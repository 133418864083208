.start-view {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  .split-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    h1 {
      font-family: "Anton", sans-serif;
      font-weight: 400;
      font-style: italic;
      margin: 0;
      font-size: 2.0em;
      text-align: center;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #404040;
      white-space: nowrap;

      &:nth-child(odd) {
        text-shadow: 0 0 0.15em $xanthous-color;
      }

      &:nth-child(even) {
        color: $xanthous-color;
        background-color: black;
        padding: 0 0.25em 0 0.05em;
      }
    }
  }

  >div:first-child {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: $res0;

    .event-image {
      z-index: 0;
      position: absolute;
      width: 100%;
      max-width: $res0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    >div {
      position: absolute;
      width: 100%;
      height: 33%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;

      &:first-child {
        top: 0;
      }

      &:nth-child(2) {
        top: 33.3%;
      }

      &:nth-child(3) {
        top: 66.6%;
      }
    }

    .countdown-box {
      font-size: 1.1em;
      font-family: $secondary-font;
      font-weight: bold;
      text-shadow: 2px 2px 5px #444;

      h3 {
        text-align: center;
      }

      .quantity,
      .label {
        color: white !important;
        font-size: 0.9em;
      }
    }

    .game-hosted-by {
      margin-top: 1.5em;
      text-align: center;
      text-shadow: 2px 2px 5px #444;

      a,
      button {
        background-color: white;
        border-radius: 5px;
        padding: 0.2em 0.35em;
        color: $primary-color;
        font-weight: 500;
        font-family: "Rubik";
        text-shadow: none;
      }
    }

    .game-actions {
      display: flex;
      gap: 0.5em;
      margin-top: 2em;
    }
  }

  // Second Child

  .invite-players {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
      text-align: center;
    }

    canvas {
      // QR Code
      display: block;
      margin: 0;
      margin-top: 1em;
    }

    a {
      color: white;
      font-family: "Rubik";
      font-weight: 500
    }
  }
}

.big-screen .start-view {
  padding: 0 1em;
  max-width: 1920px;
  margin: auto;

  .countdown {
    font-size: 1.5em;
  }
}

.mobile .start-view {
  >div:first-child {
    position: relative;
    padding-top: calc($navbar-height + 1em);
    overflow-y: auto;
    margin: 0.5em;

    >div {
      position: relative;
      height: auto;
      top: auto;

      &:nth-child(2) {
        overflow: hidden;
        min-height: 15em;
      }

      &:nth-child(3) {
        padding-top: 2em;
      }
    }
  }
}

.recorder .start-view {
  padding: 0;

  >div:first-child {
    >img {
      height: 100%;
      width: auto;
    }
  }
}
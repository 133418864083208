﻿.timer {
    position: absolute;
    transform-origin: top right;
    top: 0.35rem;
    left: 0.5rem;

    p {
        color: white;
        position: absolute;
        font-size: 1.6em !important;
        font-family: $secondary-font;
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        font-weight: 500;
    }

    circle {
        transition: stroke-dashoffset 0.35s;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }
}
$success-color: #20BF55;
$danger-color: #bb2124;
$warning-color: #f0ad4e;

@import "auth";
@import "device-frame";
@import "layout-tools";
@import "logo";
@import "splash-screen";
@import "header";
@import "hamburger";
@import "mustbelogged";
@import "error-page";
@import "contact-form";
@import "cookies-manager";
@import "quick-module";
@import "timezone-field";
@import "payment-form";
@import "table";
@import "qr-link";

body,
html {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
    font-family: 'Montserrat';
}

.outlined-with-label {
    position: relative;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    margin: auto;
    padding: 0.3em;
    display: flex;
    align-items: center;

    height: 60px;
    padding-left: 0.8em;
    padding-right: 0.8em;

    label,
    .title-label {
        position: absolute;
        top: -0.7em;
        left: 12px;
        font-size: 0.75em;
        background-color: white;
        padding-left: 3px;
        padding-right: 3px;
        // From MUI
        color: rgba(0, 0, 0, 0.6);
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1em;
        letter-spacing: 0.00938em;
    }
}

.outlined-with-label.disabled {
    color: #9e9e9e;
}

@media only screen and (max-width: $res0) {
    .outlined-with-label {
        width: 100%;
    }
}

@media only screen and (max-width: ($tablet-width - 1)) {
    .outlined-with-label {
        height: 56px;
    }
}

// withOverlay v1.1

.dark-theme {
    .with-overlay {
        background: rgba(0,0,0,0.85);
        color: white;

        p, i {
            color: white;
        }
    }
}

.with-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    background: rgba(255,255,255,0.85);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    .animated-preloader, .logo-spinner-preloader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    
    .centered {
        position: absolute;
        width: 100%;
        text-align: center;
    }
    
    .icon {
        font-size: 3em;
        display: block;
        text-align:center;
        margin: 0.2em;
    }
    
    .message {
        min-width: 250px;
    }
    
    button {
        clear: both;
        display: block;
        margin:auto;
        margin-top: 1em;
    }
}
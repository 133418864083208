﻿.category {
	position: absolute;
	display: flex;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1.5em;

	h1 {
		margin: 1em !important; // Overwrite parent
		text-align: center;
	}

	img {
		height: 6em;
	}

	.fixed-score {
		margin-bottom: 2em;
		font-weight: bold;
		background: white;
		color: black;
		padding: 0.2em 0.5em;
		border-radius: 0.2em;
	}

	p {
		font-family: $secondary-font;
		text-transform: uppercase;
		margin-bottom: 1em;
	}
}

.quiz {
	width: 80%;
	margin: 0;
	padding: 0;
	padding-top: 2.5em;
	padding-bottom: 6.5em;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%) scale(1, 1);
	max-height: 100%;
	overflow-y: auto;
	overflow: hidden;

	table {
		tbody {
			tr {
				td.td-letter {
					vertical-align: middle;
					font-size: 1em;
				}

				td {
					border-top: 0;
				}
			}
		}
	}

	h1 {
		text-align: left;
		text-shadow: 0.08em 0.08em 0.08em #333;
	}
}

.quiz .fade-in {
	opacity: 1;
	animation-name: fadeIn;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.15s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.slide-in {
	opacity: 1;
	animation-name: slideInTransition;
	/*animation-delay: 0.3s;*/
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.3s;
}

@keyframes slideInTransition {
	0% {
		opacity: 0;
		margin-left: 5em;
	}

	100% {
		opacity: 1;
		margin-left: 0;
	}
}

.quiz {
	.answer-container {
		display: block;
		float: left;
		clear: both;
	}

	.td-letter {
		padding: 0.1em 0.5em;
	}

	.td-answer {
		padding: 0.10em;

		.answer {
			font-size: 0.7em;
			display: block;
			float: left;
			color: white;
			border: 2px solid transparent;
			font-family: 'Hind', sans-serif;
			text-shadow: 0.1em 0.1em 0.1em #444;
			padding: 0.4em 0.1em;

			line-height: 1.5em;
		}
	}
}

$correct-answer-color: #00ff2a;
$selected-answer-color: #ffcd19;
$wrong-answer-color: #ee0000;

.quiz .correct.answer,
.quiz .selected.answer,
.quiz .wrong.answer {
	font-weight: bold;
	padding-left: 0.2em;
	padding-right: 0.2em;
}

.quiz .correct.answer {
	border: 0.1em solid white;
	background: $correct-answer-color;
	animation: flash-correct linear 0.16s 7;
}

.quiz .selected.answer {
	border: 0.1em solid white;
	background: $selected-answer-color;
}

.quiz .wrong.answer {
	border: 0.1em solid white;
	background: $wrong-answer-color;
}

@keyframes flash-correct {
	0% {
		background: rgba($correct-answer-color, 0.8);
	}

	50% {
		background: rgba($correct-answer-color, 0.0);
	}

	100% {
		background: rgba($correct-answer-color, 0.8);
	}
}

.letter {
	color: white;
	border-radius: 50%;
	font-weight: 600;
	width: 2em;
	height: 2em;
	border: 0.15em solid white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1em;
	padding-top: 0.15em;

	&.A {
		background: #3188e7;
	}

	&.B {
		background: #e80e00;
	}

	&.C {
		background: #6fd525;
	}

	&.D {
		background: #f8d555;
	}

	&.E {
		background: #ad31e7;
	}
}

.question-image {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	max-height: 90%;
	object-fit: contain;
}

.big-screen .question-image {
	max-height: 60%;
}

.ranking-container {
	position: absolute;
	background: rgba(0, 0, 0, 0.8);
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: auto;
	top: 0;
	padding-bottom: 7em;

	.ranking {
		overflow-y: hidden;

		h1 {
			text-align: center;
			font-size: 0.8em !important;
		}

		.ranking-col-groups-container {
			width: 100%;
		}

		.ranking-col-groups {
			flex-direction: row;
			display: flex;
			flex-wrap: nowrap;
		}

		.ranking-col-group {
			flex: 0 0 auto;
			width: 100%;
		}

		.ranking-col-group-inner {
			display: flex;
		}

		.ranking-col {
			flex: 1;
		}
	}
}

.big-screen {
	.ranking {
		width: 100%;

		h1 {
			font-size: 1em !important;
			margin: 0.5em 0;
		}
	}
}

.ranking-item {
	display: block;
	width: 96%;
	height: 1.0em;
	margin-left: auto;
	margin-right: auto;
	background: rgba(255, 255, 255, 0.9);
	border-radius: 2px;
	margin-bottom: 0.1em;
	position: relative;
	animation: fadeIn ease 1s;
}

.players-view .ranking-item {
	animation: none;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.ranking-item .position {
	position: absolute;
	font-weight: bold;
	left: 0;
}

.ranking-item .position .circle {
	position: relative;
	display: block;
	text-align: center;
	background: $secondary-color;
	border-radius: 50%;
	width: 2.4em;
	height: 2.4em;
	margin: 0.2em 0.2em 0 0.2em;
	cursor: default;
}

.ranking-item .position .circle i {
	position: absolute;
	margin-top: 0.1em;
	font-size: 1.4em;
	font-style: normal;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.ranking-item .position img {
	height: 1.6em;
	width: auto;
	margin: 0.1em 0.1em 0em 0.1em;
}

.ranking-item .name {
	position: absolute;
	left: 1.7em;
	display: flex;
	color: black;
	height: 100%;
	align-items: center;
	margin-top: 0.1em;
}

.ranking-item .score {
	position: absolute;
	right: 1.3em;
	width: 22%;
	color: black;
	font-weight: bold;
	color: $primary-color;
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
	margin-top: 0.1em;

	i {
		margin-top: -0.2em;
		margin-left: 0.2em;
		font-size: 0.7em;
	}
}

.ranking-statistics {
	display: flex;
	justify-content: space-around;
	margin-top: 0.3em;
}

.ranking-statistic {
	display: flex;
	flex-wrap: nowrap;
}

.ranking-statistic i {
	font-size: 0.7em;
	margin-right: 0.2em;
}

.ranking-statistic .label {
	color: $secondary-color;
}

.ranking-statistic .value {
	min-width: 2em;
	text-align: center;
	display: inline-block;
}

.ranking-statistic.exact i {
	color: #0fb30f;
}

.ranking-statistic.wrong i {
	color: #e81c1c;
}

.ranking-statistic.absteined i {
	color: #f3891b;
}

.ranking-item .profile-picture {
	height: 1.0em;
	width: 1.0em;
	overflow: hidden;
	background-size: cover;
	position: absolute;
	right: 0;
}

.final.ranking {
	max-width: 1000%;
	margin: auto;
}

// Modal shown version

.players-view .modal-body {
	padding: 0;
	padding-top: 0.5em;
}

.mobile,
.big-screen {
	.mini-leaderboard {
		font-size: 1.3em;
	}

	.mini-leaderboard .ranking-item {
		font-size: 1.2em;
	}

	.ranking {
		font-size: 2.0em;
		overflow-x: auto;
	}

	.quiz {
		font-size: 1.5em;
	}

	.ranking h1 {
		margin-top: 80px;
	}

	.category {
		font-size: 1.5em;
	}
}

.mini-leaderboard .ranking-item.you .name {
	font-weight: bold;
	color: $primary-color;
}

.players-view {
	.ranking-item.you .name {
		font-weight: bold;
		color: $primary-color;
	}

	.ranking-item {
		height: 2.25em;
		width: 100%;

		.name {
			left: 2.2em;
			margin-top: 0em;
			font-size: 1.2em;
		}

		.position {
			color: white;
			font-size: 0.8em;
		}

		.profile-picture {
			height: 100%;
			width: 2.25em;
			right: 0em;
		}
	}
}

.note-box {
	font-size: 0.7em;
	font-weight: 600;
	background-color: white;
	border-radius: 8pt;
	color: black;
	padding: 1em 1em;
	box-shadow: 2px 2px 5px #444;
	border: 1pt solid #222;
	text-align: center;
}

.ranking-col-groups-container {
	overflow-x: auto;
	overflow-y: hidden;
}

@media only screen and (max-width: $res0) {
	.quiz {
		width: 92%;
		top: 1em;
		transform: translateX(-50%) scale(1, 1);
	}

	.ranking-statistic .label {
		display: none;
	}
}
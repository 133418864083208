$barsColor: $primary-color;

$hamburgerIconWidth: 40px;
$hamburgerIconHeight: 30px;
$hamburgerIconBarHeight: 5px;
$hamburgerIconBarsInterSpace: 12px;

// Source: https://codepen.io/designcouch/pen/Atyop

/* Icon 1 */

.hamburger-icon {
    width: $hamburgerIconWidth;
    height: $hamburgerIconHeight;
    position: relative;
    margin: 10px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.hamburger-icon span {
    display: block;
    position: absolute;
    height: $hamburgerIconBarHeight;
    width: 100%;
    background: $barsColor;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

/* Icon 3 */

.hamburger-icon span:nth-child(1) {
    top: $hamburgerIconBarsInterSpace * 0;
}

.hamburger-icon span:nth-child(2), .hamburger-icon span:nth-child(3) {
    top: $hamburgerIconBarsInterSpace * 1;
}

.hamburger-icon span:nth-child(4) {
    top: $hamburgerIconBarsInterSpace * 2;
}

.hamburger-icon.open span:nth-child(1) {
    top: $hamburgerIconBarsInterSpace * 1;
    width: 0%;
    left: 50%;
}

.hamburger-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.hamburger-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.hamburger-icon.open span:nth-child(4) {
    top: $hamburgerIconBarsInterSpace * 1;
    width: 0%;
    left: 50%;
}

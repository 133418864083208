.recorder {
  .timer {
    top: 0.5em;
  }

  .material-icons {

    &.fa-compress,
    &.fa-volume-up {
      display: none;
    }
  }

  .quiz {
    top: 50% !important;
    transform: translate(-50%, -50%) scale(1, 1);
    padding: 0 !important;
  }
}

@keyframes game-started-image-animation {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translate(-50%, -50%) scale(1.025);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

@keyframes game-started-logo-animation {
  0% {
    transform: translateX(-50%) scale(1.5);
    top: 25%;
    opacity: 1;
  }

  50% {
    transform: translateX(-50%) scale(2.5);
    opacity: 0.8;
  }

  100% {
    transform: translateX(-50%) scale(1);
    top: 0.5em;
    opacity: 1;
  }
}

@keyframes game-started-title-animation {
  0% {
    opacity: 1;
    transform: scale(1.0);
  }

  32% {
    opacity: 1;
    transform: scale(1.2);
  }

  40% {
    opacity: 0;
    transform: scale(10);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

$game-started-animation-delay: 0.5s;
$game-started-animation-duration: 1s;
$game-started-animation-iterations-count: 1;

.recorder {
  &.pre-event {
    .game-logo {
      transform: translateX(-50%) scale(1.5);
      top: 25%;
    }
  }

  &.status-started {

    .split-title {
      animation: game-started-title-animation;
      animation-delay: $game-started-animation-delay;
      animation-duration: 4s;
      animation-iteration-count: $game-started-animation-iterations-count;
      animation-fill-mode: forwards;
    }

    .event-image {
      opacity: 0;
      animation-delay: $game-started-animation-delay;
      animation: game-started-image-animation $game-started-animation-duration ease-in-out;
      animation-iteration-count: $game-started-animation-iterations-count;
      animation-fill-mode: forwards;
    }

    .game-logo {
      animation-delay: $game-started-animation-delay;
      animation:
        // zoomFade 1s forwards,
        game-started-logo-animation calc($game-started-animation-duration * 0.7) ease-in-out;
      animation-iteration-count: $game-started-animation-iterations-count;
      animation-fill-mode: forwards;
    }
  }
}
.light-theme {
    .memberships.page {
        button span {
            color: black;
        }
    }
}

.memberships.page {
    h1 {
        text-align: center;
    }

    h3 {
        text-transform: uppercase;
        font-family: Poppins, Helvetica, "sans-serif";
        font-size: 0.9em;
        font-weight: 400;
        color: $primary-color;
        margin-top: 3em;
        text-align: center;
    }

    p {
        text-align: center;
    }

    .invoices {
        max-width: 800px;
        margin: auto;
    }

    .paid, .not-paid {
        border: 1pt solid #28aa28;
        color: #28aa28;
        text-align: center;
        float: right;
        border-radius: 5px;
        font-weight: bold;
        padding-left: 8px;
        padding-right: 8px;
    }

    .not-paid {
        border: 1pt solid #ee0000;
        color: #ee0000;
    }

    .logout-button {
        max-width: 200px;
        margin: auto;
        margin-bottom: 2em;
    }

    .manage-memberships-button {
        display: block;
        margin: auto;
        margin-bottom: 2em;
    }

    img {
        display: block;
        margin: auto;
        max-width: 512px;
    }

    a.my-account {
        width: auto;
        margin-bottom: 1em;
    }
}

@media only screen and (max-width: $res0) {
    .memberships.page {
        .logout-button {
            margin-bottom: 0.5em;
        }

        img {
            max-width: 100%;
        }
    }
}

footer {
	background-color: $dark-bg-color;
	font-size: 11px;
	padding-top: 25px;

	a {
		color: white !important;
	}

	.map {
		max-width: 1200px;
		margin: auto;
		justify-content: center;
	}

	h2 {
		text-transform: uppercase;
		color: $primary-color;
		margin-left: 0.4em;
		font-weight: 400;
		font-size: 1.2em;
		letter-spacing: .15em !important;
		font-family: Poppins, Helvetica, "sans-serif";
	}


	ul>li {
		padding-left: 0 !important;
	}

	ul>li div:first-child {
		min-width: 25px;
	}

	ul>li a,
	ul>li a:visited,
	ul>li a:focus {
		font-weight: 100;
	}

	a.logo-footer {
		width: 180px;
		margin-top: 2.0em;
		margin-bottom: 0em;

		img {
			width: 100%;
		}
	}

	.socials {
		justify-content: center;
		margin: 0;
	}

	.socials-list {
		margin-bottom: 2em;
	}

	.languages {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 2em;

		img {
			float: left;
			height: 1em;
			margin-top: 0.2em;
			margin-right: 0.3em;
		}
	}

	.signature {
		margin: 2em;

		p {
			font-size: 1.1em;
			text-align: center;
			margin: 0;
			margin-right: 0.2em;
			margin-bottom: -0.5em;
			color: #555;
		}

		img.uderly {
			width: 90px;
		}
	}
}

footer.dark {

	svg,
	span,
	div,
	p {
		color: white;
	}
}

@media only screen and (max-width: $res0) {
	footer {
		// .help-and-support {
		//     margin-top: 1em;
		// }

		ul,
		h2 {
			margin-left: 1em !important;
		}

		li {
			margin-top: 0;
			margin-bottom: 0;
			padding: 0;
		}
	}
}
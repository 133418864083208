﻿.fullscreen-message {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    opacity: 0;
    font-size: 2em;
    animation: animate-fullscreen-message 3s;
    animation-iteration-count: 1;
}

@keyframes animate-fullscreen-message {
    0% {
        opacity: 0;
        transform: translate(-50%,-50%) scale(0.0);
    }

    20% {
        opacity: 1;
        transform: translate(-50%,-50%) scale(1.0);
    }

    80% {
        opacity: 1;
        transform: translate(-50%,-50%) scale(1.1);
    }

    100% {
        opacity: 0;
        transform: translate(-50%,-50%) scale(2.5);
    }
}

.fullscreen-message h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.page.pricing {
  padding-bottom: 2em;

  h1,
  h2 {
    text-align: center;
  }

  h2 {
    font-weight: 400;
    font-size: 1.4em;
    margin-bottom: 2em;
  }

  .promo-banner {
    @extend .iqspace-bg-animation;

    width: 100%;
    padding: 2.5em;
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 1.3em;
    margin-bottom: 2.8em;

    h1 {
      font-size: 3em;
      text-transform: uppercase;
      font-weight: 600;
      // font-family: 'Rubik';
    }

    h2 {
      font-size: 1.0em;
      max-width: 80%;
      margin: 1.5em auto;
    }

    h3 {
      font-size: 0.6em;
    }

    img {
      width: 20%;
      margin: auto;
      margin-top: 0.5em;
    }
  }

  .plans {
    justify-content: center;

    .plan {
      display: flex;
      flex-direction: column;

      &.business {
        align-items: center;
        width: 100%;
        max-width: 30em;

        .MuiCardHeader-root {
          @extend .iqspace-bg-animation;

          color: white;
        }

        >div {
          width: 100%;
        }

        .pricing-card-content {
          align-items: center;
        }

        .cta-button {
          margin: auto;
        }
      }

      >div>a {
        max-width: 20em;
      }

      .MuiCardContent-root {
        flex: 1;
      }

      h2 {
        font-size: 1.5em;
        font-weight: 600;
        margin-bottom: 00em;

        s {
          font-weight: 400;
          color: $onyx-color;
        }
      }

      small {
        text-align: center;
        color: #9499ac;
      }

      .item {
        width: 100%;
        position: relative;
        text-align: left;
        display: flex;
        align-items: flex-start;

        p,
        a {
          display: inline-block;
          margin-left: 1em;
          font-size: 1em;
          margin-bottom: 0.8em;
          text-align: left !important;
        }

        svg {
          width: 2em;
          margin-top: 0.3em;
          font-size: 1em;

          circle {
            color: #ffcc00;
          }
        }
      }
    }
  }

  span.MuiCardHeader-subheader {
    color: $primary-color;
    font-size: 0.75em;
    font-weight: 600;
    font-family: 'Poppins';
    text-transform: uppercase;
  }

  .ticket-fees {
    margin-top: 3em;

    p {
      font-size: 0.9em;
    }

    svg {
      color: #0d6efd;
    }

    .ticket-image {
      img {
        width: 100%;
        border: 0.5em solid #b7c9e4;
      }
    }
  }
}

@media only screen and (max-width: $res0) {
  .page.pricing {
    .promo-banner {
      padding: 2.0em;

      h1 {
        font-size: 2.5em;
      }

      h2 {
        font-size: 0.8em;
        max-width: 100%;
      }

      h3 {
        font-size: 0.6em;
      }

      img {
        width: 60%;
      }
    }

    .pricing-card {
      min-height: auto;
    }
  }
}
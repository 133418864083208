.account-page {
    display: flex;
    width: 100%;
    max-width: $page-target-width;
    padding: 1em 1em 1em 1em;
    max-height: 100%;
    overflow: hidden;
    flex-direction: column;

    .module-view {
        width: 100%;
        flex: 1;
        margin: 0;
        padding-bottom: 2em;
    }

    h2 {
        font-size: 1.2em;
        font-weight: 400;
        text-align: center;
    }
}

.set-change-password-dialog {
    button.warning-button {
        display: block;
        margin: auto;
        margin-bottom: 1em;
    }
}
.error-page {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 64px;
    margin-top: 2em;
  }

  >svg {
    color: $warning-color;
    width: 4em;
    height: 4em;
  }
}
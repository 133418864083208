.layout-dialog.cookies-manager {
  .header {
    .content {
      display: flex;
      justify-content: flex-start;
      font-family: 'Poppins';
      font-size: 0.8em;

      .uderly-logo {
        width: auto;
        height: 2em;
        margin-right: 1em;
        margin-bottom: 0.5em;
      }
    }
  }

  h1 {
    font-size: 1.4em;
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: 1.2em;
    margin-top: 1.0em;
    margin-bottom: 0.5em;
  }

  .cookie-collapsible-card {
    .cookie-description {
      margin-left: 2em;
      font-size: 0.85em;
    }
  }

  .more-info {
    margin-bottom: 1em;
    font-size: 0.8em;
  }

  .cookie-settings {
    margin-bottom: 1em;

    button {
      display: block;
      margin: auto;
      margin-top: 1.6em;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    padding-top: 1em;
    padding-bottom: 0.5em;
    gap: 1em;
  }
}
.quick-module {
  position: relative;
  padding: 1em 1em 2.5em 1em;

  &.no-padding {
    padding: 0;
  }

  .no-items {
    text-align: center;
    color: $inactive-gray-color;
  }

  &.paginated {
    .MuiDataGrid-root {
      .MuiDataGrid-footerContainer {
        display: block;

        .MuiTablePagination-root {
          display: block;
        }
      }
    }
  }

  .MuiDataGrid-root {
    border: 0;

    .MuiDataGrid-footerContainer {
      display: none;

      .MuiTablePagination-root {
        display: none;

        p {
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }

    .MuiDataGrid-row {
      .info-icons {
        display: flex;
        align-items: center;
      }
    }
  }

  .records-view-actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0.25em;
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;

    * {
      pointer-events: auto;
    }
  }

  .quick-module-view-container {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    z-index: 1060;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 1100;

    .quick-module-view {
      position: relative;
      min-width: 400px;
      background-color: white;
      max-width: $target-width;
      min-height: 20vh;
      padding: 1em;
      margin: 1em;
      border: 1px solid #aaa;
      border-radius: 5px;
      box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
      display: flex;
      flex-direction: column;

      .quick-module-header {
        display: flex;
        align-items: center;
        padding: 0.5em;
        gap: 0.5em;

        >p {
          margin: 0;
          padding: 0;
        }

        button {
          border: 1px solid #aaa;
        }
      }

      .quick-module-content {
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
        margin-bottom: 1.5em; // Required for footer

        >form {
          position: absolute;
          width: 100%;
          bottom: 0;
          right: 0;
        }

        .module-view {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-res) {
  .quick-module {
    .quick-module-view-container {
      .quick-module-view {
        margin-top: 5em;
        min-width: 50%;
        width: 94%;
        padding: 0;
      }
    }
  }
}
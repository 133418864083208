@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Karla:ital,wght@0,200..800;1,200..800&display=swap');

@import '../pe-icon-7-stroke/scss/pe-icon-7-stroke.scss';

// IQ Space main palette

$primary-color: #9A18C6; // Dark Violet // #d1278a;
$secondary-color: #EE4B6A; // Bright Pink (Crayola) // #3F84E5; //#2d8dff;
$tertiary-color: #FFAF47;

$xanthous-color: #FFBC42; // Typical for titles
$amaranth-color: #DF3B57; // Aamaranth
$caribbean-color: #0F7173; // Caribbean Current

$dark-bg-color: #0A2342;

$flame-color: #D8572A;
$chilli-red-color: #E9190F;
$raisin-black-color: #2D232E;
$onyx-color: #474448;
$inactive-gray-color: #969bb3;
$teal-color: #028090;

$mobile-width: 600px; // Mobile
$mobile-res: 600px; // Mobile
$res0: 600px; // Mobile
$res1: 800px;
$tablet-width: 1366px;
$target-width: 1600px;
$page-target-width: 1600px;
$navbar-height: 70px;
$large-margin: 40px;

$primary-font: 'Karla', sans-serif;

@import "../../../uderly/assets/scss/index.scss";

@import "animations";
@import "slick";
@import "slick-theme";
@import "buttons";
@import "events";
@import "framework";
@import "footer";
@import "iqspace";
@import "iq-dashboard";
@import "memberships";
@import "organizations";
@import "quiz-console";
@import "quiz-package";
@import "store-settings";
@import "auth";
@import "pricing";
@import "wave-background";
@import "media-recorder";
@import "words";
@import "ad-viewer";
@import "playlists-select";

iframe {
    pointer-events: none;
}

body,
html {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
    font-family: 'Montserrat';
    font-size: 12pt;
}

@media only screen and (max-width: $res0) {

    body,
    html {
        font-size: 11pt;
    }
}

@media only screen and (min-width: $tablet-width) {

    body,
    html {
        font-size: 13pt;
    }
}

@media only screen and (min-width: $target-width) {

    body,
    html {
        font-size: 14pt;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    cursor: default;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $primary-font;
}

h1 {
    font-size: 2em;
}

h3 {
    font-size: 1.5em;
}

h4 {
    font-size: 1.2em;
}

a,
button {
    // color: #ffffff;
    transition: color 0.1s linear;
    text-decoration: none;
}

a:hover {
    color: $primary-color;
}

@media only screen and (max-width: $tablet-width) {
    .tablet-hidden {
        display: none !important;
    }
}

@media only screen and (max-width: $res0) {
    .mobile-hidden {
        display: none !important;
    }
}

@media only screen and (min-width: $res0) {
    .mobile-only {
        display: none !important;
    }
}

// .light-theme {

//     a,
//     button {
//         color: #000;
//     }
// }

.dark-theme {
    .MuiButton-outlined.Mui-disabled {
        border-color: #444 !important;
        color: #777 !important;
    }

    //     .block-container {
    //         background-color: black;

    //         p, span, label {
    //             color: white;
    //         }
    //     }
}

.light.MuiButton-outlined {
    border: 1pt solid #000 !important;
    color: black !important;
}

.light.MuiButton-outlined.Mui-disabled {
    border: 1pt solid #777 !important;
    color: #777 !important;
}

.following-button {
    margin: 1em 1em 2em 1em;
}

.following-button.not-interested,
.following-button.interested {
    border-color: white !important;
    color: white !important;
    margin-bottom: 1em;
}

.following-button.not-interested {
    background-color: white !important;
}

.following-button.follow,
.following-button.not-interested {
    color: $primary-color !important;
}

.text-center {
    text-align: center;
}

.anchor {
    display: block;
    position: relative;
    top: -($navbar-height);
    visibility: hidden;
}

@media only screen and (max-width: $res0) {
    .anchor {
        top: 0;
    }
}

.page {
    padding-top: calc($navbar-height + 1em);

    b {
        font-weight: 600;
    }
}

.page.target-width {
    width: 100%;
    max-width: $target-width;
    margin: auto;
}

.page-changed {
    animation: slide-in 0.8s 1;
}

@keyframes slide-in {
    0% {
        //transform: translateY(10%);
        opacity: 0.8;
    }

    100% {
        //transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes scale-out-in {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(0.95);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.preloader-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-spinner-preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2em;

    img {
        height: 64px;
        animation: fade 0.5s ease-in-out infinite;
    }

    .splash-spinner {
        margin: 0;
        margin-top: 1em;
    }

    .splash-spinner .path {
        color: $secondary-color;
    }
}

.fullscreen-preloader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo-spinner-preloader {
        width: 100%;
        height: 100%;
    }

    .powered-by {
        position: absolute;
        bottom: 2em;
        width: 115px;

        img {
            width: 100%;
        }
    }
}

@media only screen and (max-width: $res0) {
    .page {
        padding-left: 10px;
        padding-right: 10px;
    }
}

// Scrollbars
@media only screen and (min-width: $res0) {
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    // Track
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    // Handle
    ::-webkit-scrollbar-thumb {
        background: #777;
    }

    // Handle on hover
    ::-webkit-scrollbar-thumb:hover {
        background: $primary-color;
    }
}

.legal-container {
    padding-top: 100px;
    // color: white;
}

.separator-container {
    text-align: center;
    position: relative;

    .separator {
        color: $primary-color;
        margin: 0 auto 1em;
        width: 11em;
    }

    .separator.line-separator:before,
    .separator.line-separator:after {
        display: block;
        width: 40%;
        content: " ";
        margin-top: 14px;
        border: 1px solid $primary-color;
    }

    .separator.line-separator:before {
        float: left;
    }

    .separator.line-separator:after {
        float: right;
    }
}

.dark-button {
    color: white !important;
    background-color: #222;
}

.dark-button:hover {
    background-color: darken($color: (
            $primary-color),
        $amount: 30
        );
}

header {
    .nav-bar {
        .menu {
            button {
                a {
                    color: white;
                }
            }
        }
    }
}

@media only screen and (max-width: $res0) {
    header {
        .nav-bar {
            .logo {
                transform: translateX(-50%);
                top: 1em;
            }
        }
    }
}

.small-uppercase-title {
    text-transform: uppercase;
    color: #555;
    font-weight: 700;
    font-size: 1.2em;
    text-align: left;
    letter-spacing: .15em !important;
    font-family: Poppins, Helvetica, "sans-serif";
    cursor: default;
}

.logo-64 {
    height: 64px;
}

.color-banner {
    min-height: 300px;
    padding: $large-margin 5px;
    margin: 20px 0px;
    background-color: $primary-color;
    color: white;
    text-align: center;
}

@media only screen and (max-width: $res0) {

    .color-banner {
        margin: 0px 0px;
    }
}

.white-banner {
    min-height: 300px;
    padding: $large-margin 5px;
    margin: 20px 5px;
    // background-color: #004b89;
    // background-image: url("../images/white-separator.jpg");
    color: black;
    text-align: center;
}

.block-container {
    background-color: white;
    width: 100%;
    margin: 0;
    padding: 15px;
    padding-top: $large-margin;

    .block {
        width: 100%;
        max-width: $target-width;
        margin: auto;

        >* {
            padding-left: 0;
            padding-right: 0;
        }

        .paragraphs-col {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-left: 3em;
        }

        .flex-centered {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .flex-start {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .flex-end {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        h3 {
            margin-bottom: 15px;
        }
    }

    // .block.row {
    //     margin: 0 !important;
    // }
}

@media only screen and (max-width: $res0) {
    .block-container {
        padding-top: 15px;

        .block {
            .paragraphs-col {
                padding-top: 1em;
                padding-left: 1em;
            }
        }
    }
}

.block-container.mission {
    .how-we-see-the-world {
        padding: 0 5px;
        margin-bottom: 0px;

        i {
            font-size: 100px;
            color: $primary-color;
            margin-bottom: 20px;
        }

        .row {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
}

.block-container.extra-fun {
    .check-list {
        margin: 0;
    }
}

.who-are-we {
    img {
        height: 64px;
        margin-bottom: 1em;
    }
}

.golf-banner {
    height: 800px;
    margin: 14px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-image: url("../images/iq-space-business.jpg");
    background-position: center center;
    color: white;

    p {
        color: #222;
        // text-shadow: 1px 1px 3px #999;
        font-weight: 400;
        margin-top: 1em;
        font-size: 1.2em;
    }
}

@media only screen and (max-width: $res0) {
    .golf-banner {
        height: 500px;
        background-size: cover;

        p {
            font-size: 1.0em;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.block-container.video {
    h1 {
        text-align: center;
    }

    >* {
        margin: auto;
    }
}


.MuiFormControl-root {
    width: 100%;
}

.request-form {
    position: relative;

    // Text Fields
    form>div>div,
    .MuiFormControl-root {
        width: 100%;
    }

    .submit-box {
        button {
            margin: auto;
            display: flex;

            .MuiButton-label {
                display: flex;
            }
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}

.home.page {
    padding-top: 0; // Override .page
    color: black;

    .gallery {
        background-color: black;

        .slick-slider,
        .slick-list,
        .slick-track,
        .slick-slide {
            width: 100%;
            height: 100%;
        }

        .slick-slide>div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;

            .centered-slide {
                display: flex !important;
                align-items: center;
            }
        }

        .slick-dots {
            bottom: 5em !important;
        }
    }

    .in3d {
        background-color: black;
        width: 100%;
        max-width: $target-width;
        //height: 300px;
        max-height: 50%;
        margin: auto;

        .zoom-buttons {
            position: absolute;
            bottom: 5px;
            left: 5px;
            background: rgba(255, 255, 255, 0.7);

            button {
                margin: 0 5px;
            }
        }
    }

    .slide-down {
        z-index: 3;
        font-size: 32px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 1em;
        color: white;
        cursor: pointer;
    }

    .player {
        max-width: 100%;
    }
}


@media only screen and (max-width: $res0) {
    .gallery {
        margin-left: -10px;
        margin-right: -10px;
    }
}

.mui-icon.large,
img.large {
    width: 5em;
    height: 5em;
}

i.icon.large {
    font-size: 4em;
}

.can-help-with {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    max-width: 100%;
    width: 1200px;
}

@media only screen and (max-width: $res0) {
    .can-help-with {
        flex-direction: column;
        align-items: center;
    }
}

.friends {
    .logos {
        max-width: 1200px;
        text-align: center;
        margin: auto;

        img {
            height: 64px;
            margin: 20px;

            max-width: 100%;
        }
    }
}

.board {
    .img-circle {
        border-radius: 50%;
        border: 3px solid $primary-color;
        height: 128px;
        display: block;
        margin: auto;
        margin-bottom: 1em;
    }

    h3 {
        font-weight: 400;
        text-transform: uppercase;
        text-align: center;
    }

    h4 {
        text-align: center;
        color: #444;
        font-weight: 400;
        margin-bottom: 1.5em;
    }

    a {
        color: #555;
    }

    b {
        font-weight: 600;
    }

    .social-buttons {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: $res0) {
    .board {
        .luigi {
            padding-bottom: 2em;
        }

        .social-buttons {
            i {
                font-size: 1.5em;
            }
        }
    }
}

.work-with-us {
    // padding: 50px 5px;

    h1 {
        text-align: center;
    }

    .body {
        position: relative;
        padding: 30px 5px;
        max-width: $target-width;
        margin: auto;
        background: rgba(255, 255, 255, 0.9);
    }
}

.tech-carousel {
    margin-bottom: 1em;

    .slick-dots {
        top: 100% !important;

        li button:before {
            color: #333;
        }

        li.slick-active button:before {
            color: #000;
        }
    }
}

.technologies {
    // color: white;

    h1 {
        color: black;
    }

    .tech-card {
        position: relative;
        width: 100%;
        min-height: 300px;

        // background-image: url("../images/uderly_tech_background.jpg");
        // background-size: cover;
        // background-position: center;

        .content {
            position: absolute;
            padding: 1em;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            a:hover {
                color: $primary-color;
            }

            h1 {
                color: #222;
                font-size: 1.0em;
                color: $primary-color;
                text-align: left;
                padding-top: 0.5em;
                padding-bottom: 0.5em;
                text-transform: uppercase;
                font-family: 'Poppins';
                text-align: center;
            }

            p {
                font-size: 0.8em;
                // color: white;
                line-height: 1.2em;
                text-align: center;
            }

            a {
                position: absolute;
                right: 1em;
                bottom: 0.8em;
            }

            img {
                height: 6em;
                margin: 1em auto;
            }
        }
    }
}

@media only screen and (max-width: $res0) {
    .technologies {
        .tech-card {
            margin-bottom: 1em;
            min-height: 220px;
        }
    }
}

.link-button {
    color: $primary-color;
    cursor: pointer !important;
    border: none;
    background: transparent;
}

.browser {
    max-width: 916px !important;
    width: 98%;
    margin: auto;
    margin-top: 1em;
    overflow: hidden !important;
    max-height: 500px;

    .slick-slide img {
        max-width: 900px;
        width: 100%;
    }

    .slick-arrow {
        display: none !important;
    }

    .slide-box {
        height: 452px;
        overflow: hidden;
    }
}

@media only screen and (max-width: $res0) {
    .browser .slide-box {
        height: auto;
        margin-top: 0;
    }
}

.socials-slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .slide-uderly-logo {
        img {
            height: 64px;
        }
    }

    p {
        padding: 1em;
        color: #ccc;
        max-width: 540px;
        text-align: center;
    }

    h6 {
        margin-top: 3em;
        color: white;
        text-transform: uppercase;
        font-size: 0.6em;
        text-align: center;
    }
}

.error-page {
    margin-top: $navbar-height;
    padding: 1em 0;
}

.image-frame {
    border: 0.5em solid #b7c9e4;
}

.glamour-info-box {
    border: 1px solid #eee;
    padding: 1em;
    margin: 1em;
    background-color: rgba($primary-color, 0.7);
    color: white;
}

.socials-list {
    display: flex;
    gap: 15px;

    .navigation {
        width: 2.5em;
    }

    a {
        color: white;
        font-size: 2em;
        width: auto;
    }

    a:hover {
        text-decoration: none;
    }

    &.dark {
        a {
            color: #282828;
        }
    }

    &.small {
        gap: 10px;

        a {
            font-size: 1.4em;
        }
    }
}

// TODO: To be exported to lib

.hexagon-border-alt {
    --b: 1px;
    /* adjust to control the border  */
    height: 100%;
    aspect-ratio: cos(30deg);
    background-color: transparent;
    clip-path:
        polygon(50% 0, -50% 50%, 50% 100%, 150% 50%, 50% 0,
            50% var(--b),
            calc(100% - var(--b)*sin(60deg)) calc(25% + var(--b)*cos(60deg)),
            calc(100% - var(--b)*sin(60deg)) calc(75% - var(--b)*cos(60deg)),
            50% calc(100% - var(--b)),
            calc(var(--b)*sin(60deg)) calc(75% - var(--b)*cos(60deg)),
            calc(var(--b)*sin(60deg)) calc(25% + var(--b)*cos(60deg)),
            50% var(--b));
    background: linear-gradient(60deg, #ffaf47, $primary-color);
}

.socials {
    display: flex;
    gap: 0.5em;

    .navigation {
        position: relative;
        padding: 0;
        height: 3em;
        color: white;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:hover {
            .hexagon-border-alt {
                clip-path: polygon(-50% 50%, 50% 100%, 150% 50%, 50% 0);
                background: linear-gradient(60deg, #bf4d28, $tertiary-color);
            }
        }
    }
}
.device-frame {
  position: relative;

  .display {
    position: relative;
    width: 100%;
    transform: scale(0.97);
    overflow: hidden;
  }

  img.frame-image {
    position: absolute;
    transform: translate(-50%, 0%);
    top: 0%;
    left: 50%;
  }

  &.tablet {
    .display {
      margin-left: -0.65%;
    }
  }

  &.phone {
    .display {
      transform: scale(0.94);
      display: flex;
      justify-content: center;
      align-items: start;
    }

    img {
      top: 0;
    }
  }

  &.tv {
    >svg {
      position: absolute;
      bottom: -0.1%;
    }

    .display {
      background-color: #000;
      display: flex;
      justify-content: center;
      border: 0.5em solid #282828;
      border-radius: 6px;
    }
  }
}
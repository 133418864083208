.check-list {
    max-width: 630px;
    margin: auto;
    position: relative;

    .item {
        svg {
            position: absolute;
            left: 0;
        }

        p,
        a {
            display: block;
            margin-bottom: 1em;
        }

        small {
            a {
                display: inline;
                margin: 0;
            }
        }
    }

    p,
    a {
        margin-left: 2em;
        text-align: left !important;
    }

    svg {
        width: 2em;
        font-size: 1em;
        color: #00cc00;
    }

    // .tab { width: 2em; float: left; }
}

.request-form {

    // Text Fields
    form>div>div {
        width: 100%;
    }

    .submit-box {
        button {
            margin: auto;
            display: block;
        }
    }
}

.organizations {
    font-size: 14pt;

    // .row {
    //     background-color: white;

    //     p, label, span, h1, h2, h3, h4, h5, h6 {
    //         color: black;
    //     }
    // }

    h1 {
        margin: 1em;
        text-align: center;
    }

    p,
    h2,
    h3,
    h5 {
        text-align: center;
    }

    img,
    svg {
        display: block;
        margin: auto;
    }

    .row {
        margin-top: 3em;
        margin-bottom: 3em;
    }

    .row-0 {
        .col-0 {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            p {
                height: 5.5em;
            }
        }
    }

    .row-1 {
        margin-top: 2em;

        .icon {
            width: 128px;
            margin-bottom: 1em;
        }
    }

    .row-2 {
        h3 {
            margin-bottom: 2em;
        }
    }

    .row-3 {
        h1 {
            text-align: center;
        }

        h3 {
            margin-bottom: 0.5em;
        }
    }

    .row-5 {
        a.uderly {
            margin-top: 2em;

            img {
                height: 64px;
            }
        }

        h3 {
            margin-bottom: 0.5em;
        }
    }

    .row-7 {
        .fa-magic {
            font-size: 3em;
            display: block;
            text-align: center;
            margin-top: 0.3em;
            margin-bottom: 0.5em;
            color: #ccc;
        }

        a {
            margin-top: 0.5em;
            margin-bottom: 1em;
        }
    }

    .bond-night-images {
        img {
            max-width: 100%;
        }
    }

    .player {
        margin: auto;
    }

    .small {
        font-size: 0.88em;
        color: #777;
    }

    .mui-icon.large {
        color: #ccc;
    }

    .team-cover {
        width: 100%;
        max-width: 640px;
        border-radius: 20px;
    }

    .tag-line {
        margin: 0;
        margin-top: 2em;
        margin-bottom: 1em;
    }

    .mui-icon.big,
    .mui-icon.big .path {
        width: 5em;
        height: 5em;
        margin: 1em auto;
        color: #ccc;
    }

    .partners {
        img {
            height: 128px;
            margin: 0 10px;
        }

        .rotaract {
            img {
                height: 64px;
            }
        }
    }
}

@media only screen and (max-width: $res0) {
    .organizations {
        .player {
            width: 100% !important;
        }

        .row-0 {
            margin-top: 1em;
        }
    }
}

.promo-banner {
    .promo-title {
        text-align: center;
        // font-size: 16pt;
        // color: #e21272;
        color: $primary-color;
        font-weight: bold;
        text-transform: uppercase;
    }

    .promo-title .material-icons {
        font-size: 14pt;
        margin-bottom: -3px;
    }

    .promo-balloon {
        max-width: 100%;
        padding: 20px 20px;

        .countdown {
            font-size: 1.2em;
        }
    }

    .expiration-date {
        padding-top: 15px;
        text-align: center;
    }
}

.ready-list {
    .image-box {
        margin-bottom: 2em;

        >img {
            width: 100%;
            max-width: 512px;
        }
    }
}
@import "store-toolbar";

// Dashboard

$avatar-size: 128px;
$tablet-avatar-size: 96px;
$mobile-avatar-size: 80px;
$padding: 13px;
$mobile-frame-padding: 48px;

.flex-break {
	flex-basis: 100%;
	height: 0;
}

.store-not-found {
	margin-top: $navbar-height;
	padding-top: 2em;
	font-size: 1em;
	text-align: center;
}

.iq-dashboard.loading {
	justify-content: center;
	min-height: 300px;
}

.iq-dashboard.as-viewer {
	height: auto !important;
}

.iq-dashboard {
	overflow: hidden;
	max-width: $target-width;
	padding: 0;
	padding-top: $navbar-height !important;
	display: flex;
	flex-direction: column;
	margin: auto;

	.header {
		padding: 0 $padding;
		z-index: 1;
	}

	.full-height {
		flex-grow: 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}

	.footer {
		justify-self: flex-end;
		height: 24px;
		padding: 0.2em 0.5em;
		font-size: 0.7em;
		color: #ccc;

		a {
			color: black;
		}
	}

	.widget {
		width: 100%;
	}

	.store.widget {
		display: flex;
		flex-wrap: wrap;
		box-shadow: none;
		background-color: transparent;
		position: relative;
		padding: 0px;
		justify-content: center;

		h1,
		h2 {
			font-family: Poppins;
		}

		h2 {
			font-size: 1em;
			margin-bottom: 0.5em;
			text-transform: uppercase;
			color: #555;
		}

		.avatar-container {
			display: flex;
			justify-content: center;
			align-items: center;
			width: $avatar-size + 20;
			height: $avatar-size + 20;
			margin: 1em;

			.avatar {
				max-width: $avatar-size !important;
				width: $avatar-size !important;
				height: $avatar-size !important;
				background-size: cover;
			}
		}

		.edit-store {
			position: absolute;
			top: calc(0.9em);
			right: $padding;
		}

		h1,
		h2,
		.description {
			width: 100%;
			text-align: center;
		}

		.social-components {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.following-button {
				margin: 1em 0 !important;
			}

			// .following-button.follow {
			//     color: $primary-color !important;
			// }

			.socials-list.small {
				margin-top: 1em;
			}
		}
	}

	.MuiTabs-root {
		min-height: 64px;

		.MuiTabs-flexContainer {
			>button {
				margin: 0 1em;
			}
		}
	}

	.quiz-widget {
		font-family: "Rubik";
		position: relative;
		padding-top: 1em;

		.quick-module {
			padding: 80px 0;
		}

		h1,
		h2,
		h3 {
			text-align: center;
		}

		th {
			text-align: left;
		}

		h2 {
			font-weight: 500;
			margin-top: 0.5em;
			margin-bottom: 0.5em;
			font-size: 1.2em;
		}

		.uppercase-bold {
			text-transform: uppercase;
			font-weight: 500;
		}

		.framed-center {
			display: grid;
			margin-left: 42px;
			margin-right: 42px;
		}

		.action-button.start-game,
		.action-button.start-game:hover {
			height: 2em;
			margin-left: 0.5em;
			margin-right: 0.5em;
		}

		.action-button.stop-game,
		.action-button.stop-game:hover {
			margin-left: 0.5em;
			margin-right: 0.5em;
			background-color: $danger-color;
			color: white;
		}

		.quick-module {
			padding: 0 80px;
		}

		// td .td-v-center {
		// .start-game,
		// .start-game:hover {
		//     float: right;
		//     margin-right: 0;
		// }
		// }

		.no-events {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 1em;
			padding-bottom: 0.5em;

			.start-game {
				margin-top: 2em;
				margin-bottom: 1.0em;
			}
		}

		.event {
			h1 {
				margin-bottom: 0;
			}

			.countdown-to-start {
				.countdown-box {
					margin-top: 2em;
					margin-bottom: 2.5em;
				}
			}
		}

		.tabs-container {
			padding-left: calc($mobile-frame-padding + 1.5em);
		}
	}
}

// @media only screen and (min-width: $res0) {
//     .iq-dashboard {
//         .quiz-widget {
//             .events-today {
//                 padding-left: 80px;
//                 padding-right: 80px;
//             }
//         }
//     }
// }

@media only screen and (max-width: $tablet-width) {
	.iq-dashboard {
		.store.widget {
			.avatar-container {
				width: $tablet-avatar-size + 20;
				height: $tablet-avatar-size + 20;

				.avatar {
					max-width: $tablet-avatar-size !important;
					width: $tablet-avatar-size !important;
					height: $tablet-avatar-size !important;
				}
			}
		}
	}
}

@media only screen and (max-width: $res0) {
	.iq-dashboard {
		.header {
			padding: 0;
		}

		.full-height {
			overflow: auto;
			display: block;
		}

		.store.widget {
			font-size: 11px;
			padding: 0;

			.avatar-container {
				height: $mobile-avatar-size + 10;

				.avatar {
					max-width: $mobile-avatar-size !important;
					width: $mobile-avatar-size !important;
					height: $mobile-avatar-size !important;
				}
			}

			h1 {
				font-size: 1.9em;
			}

			.description {
				text-align: center;
			}

			>.qr-link {
				display: flex;
				flex-basis: 100%;
			}
		}

		.quiz-widget {
			.quick-module {
				padding: 0;
			}

			.tabs-container {
				// padding-left: $mobile-frame-padding;
				padding-left: 0;
			}
		}
	}
}

// Store Edit Page
.your-store {
	max-width: $target-width;
	margin: auto;

	button {
		width: auto;
	}

	.profile {
		h1 {
			text-align: center;
			margin-bottom: 1em;
		}

		p {
			text-align: center;
		}

		.media-image {
			margin-bottom: 1em;
		}

		.qr-link {
			margin: auto;
			max-width: 700px;

			>span {
				text-align: center;
			}
		}
	}

	.field {
		width: 100%;
	}

	button[type=submit],
	.quill {
		margin-top: 1em;
		margin-bottom: 1em;
	}

	.tabs {
		margin-top: 1em;
	}

	.connect-with-stripe {
		position: relative;
		text-align: center;
		padding-bottom: 3em;
	}

	.connect-with-stripe-button {
		width: 14em;
		border-radius: 5px;
		margin: auto;
		float: none;

		i {
			margin-right: 0.3em;
		}
	}

	.stripe-error-alert-container {
		padding: 1em;

		.stripe-error-alert {
			justify-content: center;
		}
	}

	.table-options {
		padding-right: $padding;
		padding-bottom: $padding;
	}

	.store-widget-localization {
		.timezone-field {
			margin-top: 1em;
		}
	}
}

.events-view {
	margin-top: 2em;
}
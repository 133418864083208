.reset-password-page {
  width: 100%;
  max-width: $target-width;

  .auth-component {
    display: block;
    margin: 2em auto;
  }
}

.auth-page {
  display: flex;
  height: 100%;

  .left-col {
    background-color: #222731;
    width: 600px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .logo {
      height: 64px;
    }

    footer {
      position: absolute;
      bottom: 0;
      height: 40px;
      font-size: 0.8em;
      padding: 1em;

      a {
        color: $primary-color;
        font-size: 1.2em;
        text-decoration: none;
      }
    }
  }

  .right-col {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 600px) {

  // $mobile-res
  .auth-page {
    height: auto;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    .left-col {
      flex: 1;
      height: auto;
      width: 100%;

      .logo {
        padding: 1em;
      }

      footer {
        position: relative;
      }
    }
  }
}

.auth-component {
  position: relative;
  width: 100%;
  max-width: 600px;
  min-height: 200px;

  h1 {
    text-align: center;
    margin-bottom: 0.5em;
  }

  h2 {
    text-align: center;
    font-size: 1em;
    color: #555;
    margin-bottom: 3em;
  }

  .action-box {
    width: 100%;
    margin: 2em auto;
    display: flex;
    justify-content: center;
  }

  .MuiSvgIcon-root {
    display: block;
    margin: 1em auto;
    width: 2em;
    height: 2em;
  }

  .MuiTextField-root {
    width: 100%;
  }

  .centered-label {
    color: #777;
    text-align: center;
    font-size: 0.9em;
  }

  .signin-button {
    width: 100%;
    margin-bottom: 0.5em;
  }

  .error-message {
    color: #dd0000;
    text-align: center;
  }

  .overlay-message {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(2px);
    z-index: 1;

    h1 {
      font-weight: 400;
      font-size: 1.6em;
    }

    p {
      margin: 0.5em;
      max-width: 80%;
      text-align: center;
    }

    svg {
      margin-bottom: 1em;
      width: 3em;
      height: 3em;
    }

    svg.success {
      fill: $success-color;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .MuiBox-root {
      width: 100%;
      max-width: 400p;
    }

    .allow-newsletter {
      max-width: 400px;
    }
  }
}

.account-page {
  display: black;
  margin-left: auto;
  margin-right: auto;
  margin-top: $navbar-height;
  position: relative;
  width: 100%;
  max-width: $page-target-width;
  padding: 1em 1em 1em 1em;
  min-height: 100%;

  h2 {
    font-size: 1.2em;
    text-align: center;
  }

  .account-settings {
    legend {
      margin-top: 1.5em;
    }

    legend:first-child {
      margin-top: 0em;
    }

    label {
      span {
        font-size: 0.9em;
      }
    }
  }
}

.module-view-footer {
  display: flex;

  .right {
    flex: 1;
    margin-right: 0em;
    text-align: center;
  }
}
.question-form {
    h3 {
        margin-top: 1em;
    }

    .image {
        height: 256px;
    }

    .answers {
        margin-bottom: 10px;
        width: 100%;

        >div {
            display: flex;
            gap: 0.5em;
            align-items: center;
        }

        input[type=text] {
            margin: 5px 0;
            border-radius: 3px;
            flex: 1;
            border: 1pt solid #ced4da;
            padding: 4px 2px 2px 5px;
            float: left;
        }

        input[type=checkbox] {
            margin-left: 10px;
            transform: scale(1.5);
            float: left;
        }
    }

    .checkmark-container {
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 10px;

        input {
            height: 22px;
        }
    }

    .checkmark {
        border-radius: 50%;
    }

    .fixed-score-group {
        .pro-slider {
            float: left;
        }

        .pro-slider input[type=range] {
            width: 200px;
        }

        .default {
            float: left;
            margin-left: 1em;
            margin-top: -6px;
            border: 1pt solid green;
            padding: 4px 6px 4px 6px;
        }
    }
}

@media only screen and (max-width: $res0) {
    .mobile-hidden {
        display: none;
    }
}
.layout-dialog {
  .dialog-box {
    position: relative;
    min-width: 500px;
    overflow: auto;
  }

  &.md {

    .MuiDialog-container .MuiPaper-root,
    .dialog-box {
      min-width: 1000px;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 1em 1em 0 1em;
    cursor: move;

    .content {
      flex: 1;

      h1 {
        font-size: 1.3em;
      }
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  .layout-dialog {
    .header {
      .content {
        max-width: 90%;
        overflow: hidden;
      }
    }

    .MuiDialog-container>* {
      min-width: 90%;
      max-width: auto;
    }

    .MuiDialog-container .MuiPaper-root,
    .dialog-box {
      min-width: auto !important;
    }

    .dialog-box {
      min-width: auto !important;
    }
  }
}
// Countdown v1.3

.countdown-box {
    .countdown {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-transform: uppercase;
        font-size: 10.5pt;
        cursor: default;
        flex-wrap: wrap;

        .time-quantity-box {
            display: flex !important;
            flex-direction: row;
            padding: 0 5px;
        
            .quantity {
                color: #222;
                font-weight: bold;
            }

            .label {
                margin-left: 2px;
                margin-right: 2px;
                color: #444;
                width: auto;
                background-color: transparent;
            }
        }
    }
}
$width: 256px;
$height: 256px;
$padding: 15px;

.media-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width + $padding * 2;
  min-height: $height + $padding * 2;
  background-color: #f1f1f1;
  user-select: none;
  margin: auto;

  .image {
    width: $width;
    height: $height;
    background-size: cover;
    background-position: center center;
  }

  .overlay-texts {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;

    p {
      text-align: center;
      font-weight: 500;
    }
  }

  input[type=file] {
    display: none;
  }

  .browse {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(10px);
    border-radius: 50%;
    width: 3em;
    height: 3em;

    svg {
      color: white;
      width: 100%;
      height: 100%;
    }
  }

  .options {
    display: none;
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: white;

    button {
      background-color: "#22222250";
      backdrop-filter: blur(10px);
      margin-right: 0.25em;

      svg {
        color: #fff;
      }

      svg.delete-button {
        color: #ee1e2d;
      }
    }
  }
}

.media-image:hover {
  .options {
    display: block;
  }

  .browse {
    display: block;
  }
}

.media-image.loading {
  .options {
    display: none;
  }

  .browse {
    display: none;
  }
}

.media-image.drag-over {
  background-color: $primary-color;
}

.media-image.rounded {
  .image {
    border-radius: 50%;
  }
}

.media-image.squircle {
  .image {
    border-radius: 0.5em;
  }
}

.media-image.cover {
  .image {
    border-radius: 0.5em;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.dialog-box {
  img {
    width: 100%;
  }
}

@media only screen and (max-width: $mobile-res) {
  .media-image {
    .options {
      display: block !important;
    }
  }
}
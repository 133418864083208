$success-color: #20BF55;
$danger-color: #bb2124;
$warning-color: #f0ad4e;

.warning-button.MuiButton-contained {
  color: #ffffff !important;
  background-color: $warning-color !important;
}

.warning-button:hover {
  color: #ffffff !important;
  background-color: $warning-color !important;
}

.success-button.MuiButton-contained {
  color: #ffffff !important;
  background-color: $primary-color !important;
}

.success-button:hover {
  color: #ffffff !important;
  background-color: $success-color !important;
}

.danger-button {
  &.MuiButton-contained {
    background-color: $danger-color !important;
  }

  &.MuiButton-outlined {
    &:hover {
      background-color: $danger-color !important;
      color: #ffffff !important;
    }
  }
}

.danger-link-button {
  color: $danger-color !important;
  // background-color: #F64E60 !important;
}

.danger-link-button:hover {
  color: #fff !important;
  background-color: $danger-color !important;
}

.iq-button-link {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    background-color: $dark-bg-color;
    margin: auto;
    display: flex;
    color: white;
  }

  button:hover {
    background-color: $secondary-color !important;
    color: white !important;
  }

  img {
    height: 32px;
    margin: 0 0.5em 0 0;
    display: block;
  }

  p {
    margin-top: 0.5em;
    margin-bottom: 0;
    font-size: 0.8em;
    text-align: center;
    color: #222;
  }
}
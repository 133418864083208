$font-family: "Montserrat", "Open Sans", Arial, sans-serif;

header {
  position: fixed;
  width: 100%;
  height: $navbar-height;
  z-index: 4;
  top: 0;

  .hamburger-icon {
    display: none;
  }

  .account-menu {
    position: absolute;
    display: flex;
    height: $navbar-height;
    top: 0;
    right: 1em;
    width: 4em;
    justify-content: center;

    button {
      svg {
        path {
          color: white;
        }
      }
    }
  }

  .account-menu.align-left {
    right: auto;
    left: 5px;
  }

  &.centered-layout {
    .nav-bar {
      max-width: $target-width;

      .left {
        display: flex;
        justify-content: flex-end;
        width: 50%;

        :last-child {
          margin-right: 1em;
        }
      }

      .right {
        display: flex;
        width: 50%;
      }

      .logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .menu {
        width: 100%;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
      }
    }
  }

  .nav-bar {
    display: block;
    position: relative;
    margin: auto;
    width: 100%;
    max-width: 1600px;
    height: $navbar-height;

    .logo {
      position: absolute;
      left: 0.5em;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 3em;
      }
    }

    .menu {
      position: absolute;
      right: 1em;
      height: 100%;
      display: flex;
      align-items: center;

      button {
        margin: 5px;

        a {
          color: $dark-bg-color;
          transition: color 0.1s linear;
          text-decoration: none;
          font-weight: 600;
          font-family: $font-family;
          padding: 10px 0;
          letter-spacing: 1px;
          font-size: 0.875rem;
        }

        a:hover {
          color: $primary-color;
        }
      }

      .submenu-container {
        padding-left: 1em;
        padding-right: 1em;
        font-size: 0.875rem;

        a {
          cursor: pointer;
          text-align: center;
          text-transform: uppercase;
          font-weight: 600;
          font-family: $font-family;

          i {
            margin-left: 0.5em;
          }

          i.fa-chevron-up {
            display: none;
          }
        }

        .submenu {
          a {
            padding-left: 2em;
            padding-right: 2em;
          }
        }
      }

      .submenu-container.open {
        i.fa-chevron-up {
          display: inline-block;
        }

        i.fa-chevron-down {
          display: none;
        }
      }

      .submenu {
        position: absolute;
        top: $navbar-height + 15;
        display: flex;
        flex-direction: column;
        height: 0;
        overflow: hidden;
        transition: height 0.2s ease-out;

        background: linear-gradient(172.64deg, rgba(7, 29, 48, 0.85) -31.64%, rgba(96, 116, 134, 0.5) 115.75%);
        border-radius: 1em;

        button,
        a {
          font-size: 0.9em;
        }
      }

      button.selected {
        a {
          color: $primary-color;
        }
      }
    }
  }
}

.account-menu-menu {
  .title {
    text-transform: uppercase;
    font-size: 0.7em;
    color: #777;
    font-weight: 600;
    margin-top: 1.2em;
    margin-bottom: 0.3em;
  }

  .MuiMenuItem-root {
    width: 100%;
    justify-content: flex-start;
    padding: 0.5em;

    .MuiAvatar-root {
      margin-left: -4px;

      &.MuiAvatar-square {
        border-radius: 3px;
      }
    }

    svg {
      margin-right: 8px;

      path {
        color: #999;
      }
    }
  }
}

header.scrolled {
  background-color: $dark-bg-color;

  .nav-bar .menu button.selected {
    a {
      color: $primary-color;
    }
  }

  .nav-bar .menu button a:hover {
    color: $primary-color;
  }
}

@media only screen and (max-width: $mobile-width) {
  header {
    .hamburger-icon {
      display: block;
      position: absolute;
      z-index: 1;
      left: 15px;
      top: 10px;
    }

    &.centered-layout {
      .nav-bar {
        .logo {
          position: relative;
          left: auto;
          transform: none;
          margin-bottom: 2em;
          top: 0;
        }

        .left {
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        .right {
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        .gap-box {
          display: none;
        }
      }
    }

    .nav-bar {
      overflow: hidden;

      >a {
        display: none;
      }

      .logo {
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .menu {
        top: $navbar-height;
        justify-content: center;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        height: auto;

        button {
          a {
            color: white;
          }

          margin: 0.5em 0;

          * {
            color: white;
            font-size: 1.2em;
          }
        }

        .submenu-container {
          text-align: center;
          padding-top: 6px;
          padding-right: 8px;
          padding-bottom: 6px;
          padding-left: 8px;
          margin: 5px;
          margin-bottom: 10px;

          .submenu {
            position: relative;
            top: 15px;
            background: transparent;
            color: white;
          }

          .submenu.open {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  header.open {
    background-color: black;
    height: 100%;

    .nav-bar {
      display: flex;
      height: 100%;

      .menu {
        position: relative;
        width: 100%;
        flex-direction: column;
        top: auto;
      }
    }
  }

  .layout.menu-open {
    overflow: hidden;
  }
}
$cell-size: 48px;
$mobile-cell-size: 50px;
$avatar-transition-time: 0.3s;

.store-toolbar {
  position: absolute;
  width: $cell-size;
  width: 90%;
  margin-top: 1em;

  .store-popover {
    border: 1px solid transparent;
    z-index: 1;

    .avatar-container {
      cursor: pointer;
      width: ($cell-size);
      height: ($cell-size);
      display: flex;
      justify-content: center;
      align-items: center;
      background: #e5e5e5;
      border-radius: 3px;
    }

    .info {
      display: none;
    }
  }

  .store-popover.expanded {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
    background-color: white;
    box-shadow: 3px 3px 5px #aaa;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 500px;
    min-height: 150px;

    .avatar-container {
      width: ($cell-size * 2 + 5px);
      height: ($cell-size * 2 + 5px);
      margin-left: 0.5em;
      margin-right: 0.35em;
      margin-top: 0;//calc($cell-size / 2 + 1em);
      transition: width $avatar-transition-time, margin-left $avatar-transition-time, margin-top $avatar-transition-time;

      .avatar.avatar {
        transition: transform $avatar-transition-time;
        transform: scale(2);
      }
    }

    .info {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 05px;
      max-width: 350px;

      h1 {
        font-size: 1.6em;
        margin-bottom: 0.1em;
        color: $primary-color;
      }

      h2 {
        font-family: Poppins;
        font-size: 0.7em;
        margin-top: 1em;
        text-transform: uppercase;
        color: #222;
      }

      h3 {
        font-size: 0.7em;
        margin-top: 0em;
      }

      p {
        font-size: 0.9em;
      }

      .qr-link {
        margin-top: 0em;
      }

      .description {
        height: auto;
      }

      .team {
        .members {
          justify-content: flex-start;
          height: auto;
          margin-bottom: 1em;
        }
      }

      .edit-store {
        @extend .dark-button;

        position: absolute;
        top: calc(0.9em);
        right: 15px;
      }
    }
  }

  .vertical-toolbar {
    position: absolute;
    top: calc($cell-size + 0.5em);
    display: flex;
    flex-direction: column;
    width: $cell-size;

    .cell:nth-child(1) a {
      color: #9E53C4;
    }

    .cell:nth-child(2) a {
      color: #0B74E3;
    }

    .cell:nth-child(3) a {
      color: #009384;
    }

    .cell:nth-child(4) a {
      color: #0090B8;
    }

    .cell:nth-child(5) a {
      color: #5600b8;
    }
  }

  .cell {
    width: $cell-size;
    height: $cell-size;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: $res0) {
  .store-toolbar {
    .store-popover.expanded {
      .info {
        .edit-store {
          position: relative;
          top: auto;
          margin-bottom: 1em;
          margin-left: 1em;
          min-width: 120px;
        }
      }
    }

    .cell {
      width: $mobile-cell-size;
      height: $cell-size;
    }
  }
}

// Horizontal mobile menu

@media only screen and (max-width: $res0) {
  .store-toolbar {
    position: relative;
    display: flex;
    height: $cell-size;
    width: 100%;

    .vertical-toolbar {
      position: absolute;
      top: auto;
      width: 100%;
      margin-left: 1.5em;

      .store-menu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        top: 0;

        .cell {
          margin-left: 0.3em;
          margin-right: 0.3em;
          display: flex;
          flex-direction: column;
          justify-content: center;

          label {
            font-size: 0.5em;
            text-transform: uppercase;
            font-weight: 500;
          }
        }
      }
    }
  }
}
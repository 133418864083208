.contact-form {
  position: relative;

  // Text Fields
  form>div>div,
  .MuiFormControl-root {
    width: 100%;
  }

  .submit-box {
    button {
      margin: auto;
      display: flex;

      .MuiButton-label {
        display: flex;
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    svg, .icon.large {
      width: 3em;
      height: 3em;
    }
  }
}
.action-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    float: left;
    background-color: #eaeaea;
    border-radius: 50%;
    text-align: center;
    border: 1pt solid #ccc;
    // margin-bottom: 15px;
    cursor: pointer;
    padding: 6px;
    width: 40px;
    height: 40px;
}

.action-button-tooltip {
    font-size: 10pt;
    height: 20px;
    padding-top: 2px;
}

.action-button i {
    font-size: 20px;
    margin-top: 4px;
    color: #656565;
}

.action-button.small {
    padding: 2px 0px 4px 2px;
    width: 28px;
    height: 28px;
}

.action-button.small i {
    font-size: 16px;
}

@media only screen and (max-width: $res0) {
    .action-button {
        padding: 1px 0 2px 0;
        width: 32px;
        height: 32px;
    }

    .action-button i {
        font-size: 18px;
    }

    .action-button.small {
        padding: 1px 0 2px 0;
        width: 28px;
        height: 28px;
    }

    .action-button.small i {
        font-size: 14px;
    }
}

.action-button.big {
    padding: 10px;
    width: 60px;
    height: 60px;
}

.action-button.big i {
    font-size: 30px;
    margin-top: 4px;
}

.action-button.disabled {
    opacity: 0.5;
    cursor: default;
}

.action-button.disabled:active {
    background-color: #eaeaea !important;
}

.action-button.disabled:hover {
    border: 1pt solid #ccc !important;
}

.action-button:hover {
    border: 1pt solid $primary-color !important;
}

.action-button.selected {
    background-color: $primary-color;
    color: white;

    svg {
        color: white;
    }
}

.action-button.success i {
    color: #01c72c;
}

.action-button.danger i {
    color: #dd2124;
}

.action-button.running .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
}

.action-button {

    &.square,
    &.square:hover {
        background-color: transparent;
        border-radius: 3px;
        margin-left: auto;
        margin-right: auto;
        padding: 0.5em;
        width: auto;
        height: auto;
        text-transform: uppercase;

        button {
            color: white;
        }

        .overlay {
            border-radius: 0;
        }
    }

    &.outline-icon,
    &.outline-icon:hover {
        background-color: transparent;
        border-radius: 3px;
        padding: 0.5em;
        width: auto;
        height: auto;
        text-transform: uppercase;

        button {
            color: white;
        }

        .overlay {
            border-radius: 0;
        }
    }
}
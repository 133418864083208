$miniQrSize: 26px;

.qr-link {
    display: flex;
    background-color: #eaeaea;
    border: 1pt solid #ccc;
    border-radius: 20px;
    padding: 5px 20px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 100%;

    >span {
        flex: 1;
        overflow-x: hidden;
    }

    .qr-container {
        border: 1px solid #fff;
        flex-basis: $miniQrSize;
        height: $miniQrSize;
        margin-left: 15px;
    }

    canvas {
        max-width: $miniQrSize;
        max-height: $miniQrSize;
    }
}

.qr-link:hover {
    >span {
        color: #0089fe;
    }
}

.MuiGrid-item>.qr-link {
    width: auto;
}

.qr-link-modal {
    margin-left: auto;
    margin-right: auto;
    max-width: 230px;

    .qr-container {
        display: flex;
        justify-content: center;
    }

    p {
        margin-top: 1.5em;
        text-align: center;
    }
}